import Feedback from "components/Base/Feedback/Feedback";
import { useTranslation } from "react-i18next";
import { hasPermission } from "../../utils/handlePermissions";
import FooterElements from "./FooterElements";
import FooterText from "./FooterText";

const Footer = () => {
  const { t } = useTranslation();

  return (
    <>
      {hasPermission("can_feedback") && (
        <Feedback
          header={t("feedback.header")}
          description={t("feedback.description")}
          save={t("feedback.sent")}
          cancel={t("feedback.cancel")}
          placeholder={t("feedback.placeholder")}
        ></Feedback>
      )}

      <FooterElements />
      <FooterText />
    </>
  );
};

export default Footer;

import React from "react";
import { useTranslation } from "react-i18next";

import { Box, Typography, IconButton } from "@mui/material";
import { Man, Children } from "components/Icons";

import { PERSON_TYPE_CHILDREN } from "config/consts";

import styles from "./PlusMinus.module.scss";

const PlusMinus = ({ personType, personCount, onChangePlus, onChangeMinus }) => {
  const { t } = useTranslation();

  return (
    <Box display="flex" flexDirection="row" alignItems="flex-start" justifyContent="space-between">
      <Box display="flex" alignItems="center" justifyContent="center" height="32px">
        {personType === PERSON_TYPE_CHILDREN ? <Children /> : <Man />}
        <Typography as="span" mr={1} ml={0.5} height="24px" fontSize="16px" display="inline-block">
          {personType === PERSON_TYPE_CHILDREN ? t("offers_page.filters.children") : t("offers_page.filters.adults")}
        </Typography>
      </Box>
      <Box display="flex" alignItems="center" justifyContent="center">
        <IconButton aria-label="minus" onClick={() => onChangeMinus(personType)}>
          <Box className={styles.button}>-</Box>
        </IconButton>
        <Box className={styles.count}>{personCount}</Box>
        <IconButton aria-label="plus" onClick={() => onChangePlus(personType)}>
          <Box className={styles.button}>+</Box>
        </IconButton>
      </Box>
    </Box>
  );
};

export default PlusMinus;

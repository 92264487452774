import React from "react";
import { BrowserRouter, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { QueryParamProvider } from "use-query-params";

import { HOME_PATH, LOGIN_PATH, NOTFOUND_PATH, OFFERS_PATH, SINGLE_OFFER_PATH, UNAUTHORIZED_PATH } from "config/routes";

import CookiesComponent from "components/Base/Cookies";
import Page404 from "pages/Errors/page404/Page404";
import Homepage from "pages/Homepage/Homepage";
import Offer from "pages/Offer/Offer";
import Login from "./pages/Authentication/Login";
import Page401 from "./pages/Errors/page401";
import Offers from "./pages/Offers/Offers";

const RouteAdapter = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const adaptedHistory = React.useMemo(
    () => ({
      replace(location) {
        navigate(location, { replace: true, state: location.state });
      },
      push(location) {
        navigate(location, { replace: false, state: location.state });
      },
    }),
    [navigate]
  );

  return children({ history: adaptedHistory, location });
};

const RouterComponent = () => {
  const isAuth = !!window.localStorage.getItem("qapiToken") || false;

  if (!isAuth && localStorage.getItem("locationBeforeLogIn") == null) {
    localStorage.setItem("locationBeforeLogIn", window.location.href.split(window.location.host)[1]);
  } else if (isAuth) {
    localStorage.removeItem("locationBeforeLogIn");
  }

  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <QueryParamProvider ReactRouterRoute={RouteAdapter}>
        <Routes>
          <Route exact path={HOME_PATH} element={isAuth ? <Homepage /> : <Login />} />
          <Route exact path={OFFERS_PATH} element={isAuth ? <Offers /> : <Login />} />
          <Route exact path={SINGLE_OFFER_PATH} element={isAuth ? <Offer /> : <Login />} />
          <Route exact path={LOGIN_PATH} element={<Login />} />
          <Route exact path={UNAUTHORIZED_PATH} element={<Page401 />} />
          <Route exact path={NOTFOUND_PATH} element={<Page404 />} />
          <Route path={"*"} element={<Page404 />} />
        </Routes>
        <CookiesComponent />
      </QueryParamProvider>
    </BrowserRouter>
  );
};

export default RouterComponent;

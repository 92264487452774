import { useTranslation } from "react-i18next";
import RelatedOfferItem from "./RelatedOfferItem";
import Slider from "react-slick";
import Style from "./RelatedOffers.module.scss";
const RelatedOffers = ({ relatedOffers }) => {
  const settings = {
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    className: `${Style.list}`,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 650,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const { t } = useTranslation();
  const limitedSimilarOffer =
    (relatedOffers.similarOffers &&
      relatedOffers.similarOffers.offers &&
      relatedOffers.similarOffers.offers.length > 0 &&
      relatedOffers.similarOffers.offers) ||
    [];

  return (
    <>
      <h2>{t("offer_page.related_offers")}</h2>

      <Slider {...settings}>
        {limitedSimilarOffer.map((offer) => (
          <RelatedOfferItem offer={offer} key={offer.id} />
        ))}
      </Slider>
    </>
  );
};

export default RelatedOffers;

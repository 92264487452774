import { Box, Checkbox } from "@mui/material";
import { useTranslation } from "react-i18next";

import styles from "./Destination.module.scss";
const currency = window.localStorage.getItem("currency");

const ItemList = ({ name, countOffers, count, minPrice, id, isChecked, onChange, children }) => {
  const { t } = useTranslation();
  return (
    <li>
      <Box className={styles.item}>
        <Box>
          <Checkbox
            size="small"
            value={name}
            defaultChecked={!!isChecked && !!count}
            className={styles.checkbox}
            onChange={onChange}
            id={id}
            disabled={!count}
          />
          <span>{name}</span>
        </Box>
        <label htmlFor={id} title={name} className={!count ? styles.disabledLabel : ""}>
          {!!countOffers && !children && <span className={styles.countOffers}>{countOffers}</span>}
          {!!count && !children && <span className={`${styles.countOffers} ${styles.count}`}>{count}</span>}
          {!!minPrice && !children && (
            <span className={`${styles.countOffers} ${styles.minPrice}`}>
              {t("offers_page.filters.from") + " " + minPrice + currency}
            </span>
          )}
        </label>
      </Box>
      {children}
    </li>
  );
};

export default ItemList;

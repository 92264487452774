import { useState } from "react";

import { Box, Tooltip } from "@mui/material";
import classNames from "classnames";
import { Info, Tick } from "components/Icons";
import _ from "lodash";

import { useTranslation } from "react-i18next";
import { hasPermission } from "../../../../utils/handlePermissions";
import styles from "./SemanticList.module.scss";

const SemanticList = ({ semanticCategories, chosenCategories = [] }) => {
  const { t } = useTranslation();
  const semanticCategoriesSorted = _.orderBy(semanticCategories, "query_exists", "desc");

  const [isShowAll, setIsShowAll] = useState(false);

  const itemsCount = 7;

  const ifAllSemanticCategoryEmpty = Object.keys(semanticCategories).every(
    (key) => semanticCategories[key].length == 0
  );

  return (
    <Box className={styles.wrapperList}>
      {!hasPermission("can_view_classic_form") &&
        !hasPermission("can_view_simple_form") &&
        !ifAllSemanticCategoryEmpty && (
          <Tooltip followCursor title={t("offers_page.offers_list.tooltip_list_of_tags_for_offer")}>
            <ul className={styles.list}>
              {semanticCategoriesSorted.map((model, index) => {
                const itemClassName = classNames({ [styles.item]: true, [styles.active]: model.query_exists });
                if (model.category_name !== "accommodation") {
                  if (index <= itemsCount) {
                    return (
                      <li
                        className={
                          chosenCategories[model.category_name.toLowerCase()]?.includes(model.title)
                            ? `${styles.chosen} ${itemClassName} ${styles[model.category_name]}`
                            : `${itemClassName} ${styles[model.category_name]}`
                        }
                        key={`semantic-${model.category_name}-${model.name}`}
                      >
                        {chosenCategories[model.category_name.toLowerCase()]?.includes(model.title) && (
                          <Tick className={styles.tickIcon}></Tick>
                        )}
                        {model.title}
                      </li>
                    );
                  } else if (isShowAll) {
                    return (
                      <li
                        className={
                          chosenCategories[model.category_name.toLowerCase()]?.includes(model.title)
                            ? `${styles.chosen} ${itemClassName} ${styles[model.category_name]}`
                            : `${itemClassName} ${styles[model.category_name]}`
                        }
                        key={`semantic-${model.category_name}-${model.name}`}
                      >
                        {chosenCategories[model.category_name.toLowerCase()]?.includes(model.title) && (
                          <Tick className={styles.tickIcon}></Tick>
                        )}{" "}
                        {model.title}
                      </li>
                    );
                  }
                }
              })}
              <Info className={styles.propertyIcon} />
              {semanticCategoriesSorted.length >= itemsCount && (
                <span>
                  {isShowAll && (
                    <span onClick={() => setIsShowAll(!isShowAll)} className={styles.showMoreLessToggle}>
                      {t("offers_page.filters.hide")}
                    </span>
                  )}
                  {!isShowAll && (
                    <span onClick={() => setIsShowAll(!isShowAll)} className={styles.showMoreLessToggle}>
                      {t("offers_page.filters.view_all")}
                    </span>
                  )}
                </span>
              )}
            </ul>
          </Tooltip>
        )}
    </Box>
  );
};

export default SemanticList;

import { Box, Tooltip } from "@mui/material";
import { Info } from "components/Icons";
import { useTranslation } from "react-i18next";
import { hasPermission } from "../../utils/handlePermissions";
import { Button, DatePickerRange } from "components/Base";
import styles from "../SearchSemanticModel/SearchSemanticModel.module.scss";
import CheckboxList from "./CheckboxList";
import Destination from "./Destination";
import HotelCategory from "./HotelCategory";
import Period from "./Period";
import PriceRange from "./PriceRange";
import TransportType from "./TransportType";
import Travelers from "./Travelers";
import PriceRangeRadio from "./PriceRangeRadio";
import PriceInput from "./PriceInput/PriceInput";
import MonthList from "../Base/DatePicker/MonthList";

const SidebarFilters = ({ searchFilters, setSearchFilters, responseFilters, handleResetFilters }) => {
  const { t } = useTranslation();

  const onChangeHandler = (param) => {
    return function (event) {
      const { value, checked } = event.target || ["", false];
      switch (param) {
        case "maintenanceName":
        case "transportType":

        case "semanticCategoryActivity":
        case "semanticCategoryCuisine":
        case "semanticCategoryHotelAmenities":
        case "semanticCategoryHotelAttractions":
        case "semanticCategoryLocalization":
        case "semanticCategoryNature":
        case "semanticCategoryOther":
        case "semanticCategoryRoomAmenities":
        case "semanticCategorySkiing":
        case "semanticCategoryTripAttractions":

        case "propertySourceCategory":
        case "propertyCategory":
        case "countrySourceName":
        case "offerType":
        case "period":
        case "departureCity":
        case "regionSourceName":
        case "citySourceName":
          const newValue = searchFilters[param] || [];
          if (newValue && checked && !!value) {
            if (newValue.indexOf(value) === -1) {
              newValue.push(value);
            }
          } else {
            if (newValue.indexOf(value) !== -1) {
              newValue.splice(newValue.indexOf(value), 1);
            }
          }
          if (param === "regionSourceName" && !!value && !checked) {
            setSearchFilters({ [param]: undefined, citySourceName: undefined }, "pushIn");
          } else if (param === "countrySourceName" && !!value && !checked) {
            if (newValue.length > 0) {
              setSearchFilters({ [param]: newValue }, "pushIn");
            } else {
              setSearchFilters(
                { [param]: undefined, regionSourceName: undefined, citySourceName: undefined },
                "pushIn"
              );
            }
          } else {
            setSearchFilters({ [param]: newValue.length > 0 ? newValue : undefined }, "pushIn");
          }
          if (param === "countrySourceName") {
            let additionalRegionData = {};
            newValue.map((countryName) => {
              const country = responseFilters.countrySourceName.find((item) => item.value === countryName);
              if (country && country.regionSourceName && country.regionSourceName.length > 0) {
                additionalRegionData[countryName] = [...country.regionSourceName.map((item) => item.value)];
              }
            });
            setSearchFilters({ additionalRegionData }, "pushIn");
          }
          break;
        case "departureDate":
          const [minDate, maxDate] = event;

          if (minDate && maxDate) {
            setSearchFilters({ [param]: { min: minDate, max: maxDate } }, "pushIn");
          } else if (maxDate) {
            setSearchFilters({ [param]: { max: maxDate } }, "pushIn");
          } else if (minDate) {
            setSearchFilters({ [param]: { min: minDate } }, "pushIn");
          } else {
            setSearchFilters({ [param]: undefined }, "pushIn");
          }
          break;
        default:
          setSearchFilters({ [param]: checked && !!value ? value : undefined }, "pushIn");
      }
      setSearchFilters({ page: 1 }, "pushIn");
    };
  };

  const onChangeSliderHandler = (param) => {
    return function (event, value) {
      const urlObj = new URL(window.location.href);
      const decodedString = decodeURIComponent(urlObj.searchParams.get("period"));
      const periodsArray = JSON.parse(decodedString) || [];
      const period = event.target.value;
      if (value === true) {
        setSearchFilters({ ["periodRange"]: undefined }, "pushIn");
        if (period[0] == "d") {
          periodsArray.push({ min: 1, max: 6 });
          setSearchFilters({ [param]: periodsArray }, "pushIn");
        } else {
          periodsArray.push({ min: parseInt(period.split("-")[0]), max: parseInt(period.split("-")[1]) });
          setSearchFilters({ [param]: periodsArray }, "pushIn");
        }
        setSearchFilters({ page: 1 }, "pushIn");
      } else if (value === false) {
        if (period[0] == "d") {
          const filteredPeriodsArray = periodsArray.filter((el) => el.min !== 1 && el.max !== 6);
          setSearchFilters({ [param]: filteredPeriodsArray.length === 0 ? undefined : filteredPeriodsArray }, "pushIn");
        } else {
          const filteredPeriodsArray = periodsArray.filter(
            (el) => !(el.min === parseInt(period.split("-")[0]) && el.max === parseInt(period.split("-")[1]))
          );
          setSearchFilters({ [param]: filteredPeriodsArray.length === 0 ? undefined : filteredPeriodsArray }, "pushIn");
        }
        setSearchFilters({ page: 1 }, "pushIn");
      } else {
        const [min, max] = value;
        setSearchFilters({ ["period"]: undefined }, "pushIn");
        setSearchFilters({ [param]: { max, min } }, "pushIn");
        setSearchFilters({ page: 1 }, "pushIn");
      }
    };
  };

  const onChangePriceRadioHandler = (param) => {
    setSearchFilters({ ["price"]: param ? { max: param.max, min: param.min } : undefined }, "pushIn");
    setSearchFilters({ page: 1 });
  };

  const onChangeTravelersHandler = (param) => {
    const { adults, children, childrenBirthDates } = param;
    setSearchFilters({ adults, children, childrenBirthDates }, "pushIn");
    setSearchFilters({ page: 1 }, "pushIn");
  };

  const ifAllSemanticCategoryEmpty = Object.keys(responseFilters)
    .filter((key) => key.startsWith("semantic"))
    .every((key) => responseFilters[key].length == 0);

  const groupedObjects = responseFilters.period.reduce(
    (acc, obj) => {
      if (obj.value <= acc[0].max) {
        acc[0].count += parseInt(obj.count);
      } else if (obj.value >= acc[1].min && obj.value < acc[1].max) {
        acc[1].count += parseInt(obj.count);
      } else if (obj.value >= acc[2].min && obj.value <= acc[2].max) {
        acc[2].count += parseInt(obj.count);
      } else if (obj.value >= acc[3].min && obj.value <= acc[3].max) {
        acc[3].count += parseInt(obj.count);
      }
      return acc;
    },
    [
      { value: "do 6", count: 0, min: 1, max: 6, label: `${t("offers_page.filters.to")} 6` },
      { value: "7-9", count: 0, min: 7, max: 9 },
      { value: "10-13", count: 0, min: 10, max: 13 },
      { value: "14-17", count: 0, min: 14, max: 17 },
      { value: "input", count: 1, max: 1, label: t("offers_page.filters.any_number") },
    ]
  );

  return (
    <>
      {responseFilters.countrySourceName.length > 0 && (
        <Destination
          data={responseFilters.countrySourceName}
          selectedCountry={searchFilters.countrySourceName}
          selectedRegion={searchFilters.regionSourceName}
          selectedCity={searchFilters.citySourceName}
          onChange={onChangeHandler}
        />
      )}

      {responseFilters.offerType && responseFilters.offerType.length > 0 && (
        <CheckboxList
          title={t("offers_page.filters.offerType")}
          data={responseFilters.offerType}
          selected={searchFilters.offerType}
          onChange={onChangeHandler("offerType")}
        />
      )}
      {window.localStorage.getItem("partnerSlug") !== "baboo_travel" &&
        responseFilters.periodRange.min &&
        responseFilters.periodRange.max && (
          <Period
            minDays={responseFilters.periodRange.min || 3}
            maxDays={responseFilters.periodRange.max || 21}
            newMinDays={searchFilters.periodRange.min || 3}
            newMaxDays={searchFilters.periodRange.max || 21}
            onChange={onChangeSliderHandler("periodRange")}
          />
        )}
      {responseFilters.period && responseFilters.period.length > 0 && (
        <CheckboxList
          periodRange={responseFilters.period}
          data={groupedObjects}
          selected={searchFilters.period}
          onChange={onChangeSliderHandler("period")}
        />
      )}

      {window.localStorage.getItem("partnerSlug") !== "baboo_travel" &&
        window.localStorage.getItem("partnerSlug") !== "explore_co_uk" &&
        responseFilters.price.min !== 0 &&
        responseFilters.price.max !== 0 && (
          <PriceRange
            minPrice={responseFilters.price.min}
            maxPrice={responseFilters.price.max}
            newMinPrice={searchFilters && searchFilters.price ? searchFilters.price.min : responseFilters.price.min}
            newMaxPrice={searchFilters && searchFilters.price ? searchFilters.price.max : responseFilters.price.max}
            onChange={onChangeSliderHandler("price")}
          />
        )}

      {hasPermission("filter_price_checkboxRange") &&
        responseFilters.price.min !== 0 &&
        responseFilters.price.max !== 0 && (
          <PriceRangeRadio
            data={responseFilters.price}
            defaultMin={responseFilters.price.min}
            defaultMax={responseFilters.price.max}
            minPriceValue={responseFilters.price.min}
            minPrice={searchFilters && searchFilters.price ? searchFilters.price.min : responseFilters.price.min}
            maxPrice={searchFilters && searchFilters.price ? searchFilters.price.max : responseFilters.price.max}
            onChange={onChangePriceRadioHandler}
          />
        )}

      {hasPermission("filter_price_input") && (
        <PriceInput
          onChange={onChangePriceRadioHandler}
          minPrice={responseFilters.price.min}
          maxPrice={responseFilters.price.max}
        ></PriceInput>
      )}

      <Travelers
        adults={searchFilters.adults}
        childrenBirthDatesArr={searchFilters.childrenBirthDates}
        children={searchFilters.children}
        onChange={onChangeTravelersHandler}
      />
      {responseFilters.departureCity.length > 0 && (
        <CheckboxList
          title={t("offers_page.filters.departure_city")}
          data={responseFilters.departureCity}
          selected={searchFilters.departureCity}
          onChange={onChangeHandler("departureCity")}
        />
      )}

      {window.localStorage.getItem("partnerSlug") !== "baboo_travel" &&
        window.localStorage.getItem("partnerSlug") !== "explore_co_uk" &&
        responseFilters.transportType.length > 0 && (
          <TransportType
            title={t("offers_page.filters.transport")}
            selected={searchFilters.transportType}
            data={responseFilters.transportType}
            onChange={onChangeHandler("transportType")}
          />
        )}

      {window.localStorage.getItem("partnerSlug") !== "baboo_travel" &&
        window.localStorage.getItem("partnerSlug") !== "explore_co_uk" &&
        responseFilters.maintenanceName.length > 0 && (
          <CheckboxList
            title={t("offers_page.filters.board")}
            data={responseFilters.maintenanceName}
            selected={searchFilters.maintenanceName}
            onChange={onChangeHandler("maintenanceName")}
          />
        )}

      {responseFilters.propertyCategory && responseFilters.propertyCategory.length > 0 && (
        <HotelCategory
          title="Hotel category"
          data={responseFilters.propertyCategory}
          selected={searchFilters.propertyCategory}
          onChange={onChangeHandler("propertyCategory")}
        />
      )}

      <DatePickerRange
        title={t("offers_page.filters.dates")}
        minDate={searchFilters.departureDate?.min || responseFilters.departureDate.min}
        maxDate={searchFilters.departureDate?.max || responseFilters.departureDate.max}
        onChange={onChangeHandler("departureDate")}
        dailyPrices={responseFilters.departureDate.dailyPrices}
      />
      {hasPermission("filter_period_month") && (
        <MonthList
          data={responseFilters.departureDate.monthly}
          selected={searchFilters.departureDate || responseFilters.departureDate}
          onChange={onChangeHandler("departureDate")}
        ></MonthList>
      )}

      {ifAllSemanticCategoryEmpty == false && (
        <>
          <Tooltip followCursor title={t("offers_page.offers_list.tooltip_filter_list_by_category")}>
            <Box
              className="semanticFiltersBox"
              bgcolor="rgba(212, 245, 255, 0.5)"
              borderRadius={4}
              px={2}
              py={3}
              mt={4}
              position="relative"
              display="flex"
              flexDirection="column"
            >
              <Info style={{ alignSelf: "self-end" }} className={styles.propertyIcon} />
              {!!responseFilters.semanticCategoryActivity.length && (
                <CheckboxList
                  title={t("offers_page.filters.activity")}
                  data={responseFilters.semanticCategoryActivity}
                  selected={searchFilters.semanticCategoryActivity}
                  onChange={onChangeHandler("semanticCategoryActivity")}
                  variantColor={true}
                />
              )}
              {!!responseFilters.semanticCategoryCuisine.length && (
                <CheckboxList
                  title={t("offers_page.filters.cuisine")}
                  data={responseFilters.semanticCategoryCuisine}
                  selected={searchFilters.semanticCategoryCuisine}
                  onChange={onChangeHandler("semanticCategoryCuisine")}
                  variantColor={true}
                />
              )}
              {!!responseFilters.semanticCategoryHotelAmenities.length && (
                <CheckboxList
                  title={t("offers_page.filters.hotelAmenities")}
                  data={responseFilters.semanticCategoryHotelAmenities}
                  selected={searchFilters.semanticCategoryHotelAmenities}
                  onChange={onChangeHandler("semanticCategoryHotelAmenities")}
                  variantColor={true}
                />
              )}
              {!!responseFilters.semanticCategoryHotelAttractions.length && (
                <CheckboxList
                  title={t("offers_page.filters.hotelAttractions")}
                  data={responseFilters.semanticCategoryHotelAttractions}
                  selected={searchFilters.semanticCategoryHotelAttractions}
                  onChange={onChangeHandler("semanticCategoryHotelAttractions")}
                  variantColor={true}
                />
              )}
              {!!responseFilters.semanticCategoryLocalization.length && (
                <CheckboxList
                  title={t("offers_page.filters.localization")}
                  data={responseFilters.semanticCategoryLocalization}
                  selected={searchFilters.semanticCategoryLocalization}
                  onChange={onChangeHandler("semanticCategoryLocalization")}
                  sx={{ marginBottom: 0 }}
                  variantColor={true}
                />
              )}
              {!!responseFilters.semanticCategoryNature.length && (
                <CheckboxList
                  title={t("offers_page.filters.nature")}
                  data={responseFilters.semanticCategoryNature}
                  selected={searchFilters.semanticCategoryNature}
                  onChange={onChangeHandler("semanticCategoryNature")}
                  variantColor={true}
                />
              )}
              {!!responseFilters.semanticCategoryOther.length && (
                <CheckboxList
                  title={t("offers_page.filters.other")}
                  data={responseFilters.semanticCategoryOther}
                  selected={searchFilters.semanticCategoryOther}
                  onChange={onChangeHandler("semanticCategoryOther")}
                  variantColor={true}
                />
              )}
              {!!responseFilters.semanticCategoryRoomAmenities.length && (
                <CheckboxList
                  title={t("offers_page.filters.roomAmenities")}
                  data={responseFilters.semanticCategoryRoomAmenities}
                  selected={searchFilters.semanticCategoryRoomAmenities}
                  onChange={onChangeHandler("semanticCategoryRoomAmenities")}
                  variantColor={true}
                />
              )}
              {!!responseFilters.semanticCategorySkiing.length && (
                <CheckboxList
                  title={t("offers_page.filters.skiing")}
                  data={responseFilters.semanticCategorySkiing}
                  selected={searchFilters.semanticCategorySkiing}
                  onChange={onChangeHandler("semanticCategorySkiing")}
                  variantColor={true}
                />
              )}
              {!!responseFilters.semanticCategoryTripAttractions.length && (
                <CheckboxList
                  title={t("offers_page.filters.tripAttractions")}
                  data={responseFilters.semanticCategoryTripAttractions}
                  selected={searchFilters.semanticCategoryTripAttractions}
                  onChange={onChangeHandler("semanticCategoryTripAttractions")}
                  variantColor={true}
                />
              )}
            </Box>
          </Tooltip>
        </>
      )}
      <Box mt={5} mb={5} width="inherit" textAlign="center">
        <Button sx={{ px: 5 }} onClick={handleResetFilters}>
          {t("offers_page.filters.reset_button")}
        </Button>
      </Box>
    </>
  );
};

export default SidebarFilters;

import React from "react";
import { Button as MuiButton } from "@mui/material";

import styles from "./Button.module.scss";

const Button = ({ children, onClick, ...props }) => {
  return (
    <MuiButton
      variant="contained"
      color="secondary"
      onClick={onClick}
      disableElevation
      fontSize="12px"
      className={styles.button}
      {...props}
    >
      {children}
    </MuiButton>
  );
};

export default Button;

import React from "react";
import Loader from "react-loader-spinner";

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

import styles from "./Loader.module.scss";

const Loading = () => {
  return (
    <div className={styles.loader}>
      <Loader type="ThreeDots" color="#ff7427" height={150} width={150} />
    </div>
  );
};
export default Loading;

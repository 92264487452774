import { gql } from "@apollo/client";

export const GET_FILTERS = gql`
  query GetFilters($countryCode: Country!, $languageCode: Language!, $filters: Filters!) {
    search(countryCode: $countryCode, languageCode: $languageCode, filters: $filters) {
      facets {
        citySourceName {
          count
          value
        }
        countrySourceName {
          count
          value
        }
        regionSourceName {
          count
          value
        }
        departureCity {
          count
          value
        }
        price {
          max
          min
        }
        periodRange {
          max
          min
        }
        departureDate {
          max
          min
        }
        adults {
          count
          value
        }
        transportType {
          count
          value
        }
        maintenanceName {
          count
          value
        }
        propertySourceCategory {
          count
          value
        }
        propertyCategory {
          count
          value
        }
      }
      offers {
        type
      }
    }
  }
`;

import { Airplane, Bus, Car } from "components/Icons";
import { format } from "date-fns";

export const COMPANY_NAME = "Copyright " + format(new Date(), "yyyy") + "- Next-generation travel search";
export const COMPANY_NAME_PL =
  "Copyright " + format(new Date(), "yyyy") + "- Wyszukiwarka turystyczna najnowszej generacji";
export const COMPANY_STREET = "ul. Jabłoniowa 20/512";
export const COMPANY_CITY_WITH_POST_CODE = "80-175 Gdansk";
export const PHONE_NUMBER = "(+48) 58 326 20 00";
export const EMAIL_SALES = "sales@qtravel.ai";
export const EMAIL_SUPPORT = "support@qtravel.ai";

export const STORAGE_THEME_NAME = "qt.hp.theme.no";

export const PERSON_TYPE_CHILDREN = "children";
export const PERSON_TYPE_ADULTS = "adults";

export const TRANSPORT_TYPES = [
  { name: "flight", icon: Airplane },
  { name: "bus", icon: Bus },
  { name: "own_transport", icon: Car },
  { name: "samolot", icon: Airplane },
  { name: "autokar", icon: Bus },
  { name: "dojazd_wlasny", icon: Car },
  { name: "wlasny", icon: Car },
  { name: "autobus", icon: Bus },
];

import React, { useState } from "react";
import classNames from "classnames";
import _ from "lodash";

import { Box, Button, Checkbox, Typography } from "@mui/material";

import styles from "./CheckboxList.module.scss";
import { useTranslation } from "react-i18next";
import icon from "../../../images/icons/search-solid.svg";

const CheckboxList = ({ title, data, selected, variantColor, onChange, suffix, periodRange }) => {
  const { t } = useTranslation();
  const [isShowAll, setIsShowAll] = useState(false);
  const [inputValue, setInputValue] = useState({ min: null, max: null });
  const [isInputState, setisInputState] = useState(false);
  const [error, setError] = useState({ status: false, msg: "" });

  const wrapperClassName = classNames({
    [styles.wrapperList]: true,
    [styles.hasShadow]: false,
    [styles.variantColor]: variantColor,
  });
  let selectedHelp = selected || [{ min: null, max: null }];
  const onChangeInput = async (addDelete, oldData = {}, event = {}) => {
    const periodRangeCopy = periodRange.slice();
    setisInputState(false);
    if (addDelete) {
      event.preventDefault();
      const min = parseInt(inputValue.min) || periodRangeCopy.sort((a, b) => a.value - b.value)[0].value;
      const max = parseInt(inputValue.max) || periodRangeCopy.sort((a, b) => b.value - a.value)[0].value;
      if (min <= max && min != null && max != null) {
        await onChange({ target: { value: `${oldData.min}-${oldData.max}` } }, !addDelete);
        await onChange({ target: { value: `${min}-${max}` } }, addDelete);
        setError({ status: false, msg: "" });
      } else {
        setError({ status: true, msg: t("offers_page.filters.priceInputErrorMin") });
      }
    } else {
      onChange(
        {
          target: {
            value: `${inputValue.min == null ? selectedHelp.min : parseInt(inputValue.min)}-${
              inputValue.max == null ? selectedHelp.max : parseInt(inputValue.max)
            }`,
          },
        },
        addDelete
      );
    }
  };

  let checkedCount = 0;
  const currency = window.localStorage.getItem("currency");
  return (
    <Box mb={3} mx={0} borderRadius={4}>
      <Typography as="h3" color="#000" fontFamily="Sharp Grotesk Medium" fontSize="16px" fontWeight="bold" mb={1}>
        {title}
      </Typography>

      <Box className={wrapperClassName}>
        <ul className={styles.list}>
          {data.map(({ label, value, count, countOffers, minPrice, month, year, min, max }, index) => {
            const key = `filter-${_.kebabCase(title)}-${_.kebabCase(value)}-${index}`;
            let isChecked =
              selected === value ||
              (Array.isArray(selected) && selected.indexOf(value) !== -1) ||
              (Array.isArray(selected) && selected.find((el) => el.min === min && el.max === max));
            selectedHelp = isChecked
              ? selectedHelp.map((el) => {
                  return el.min == min && el.max == max ? {} : el;
                })
              : selectedHelp;
            let isInput = false;
            if (month && year && selected) {
              if (
                selected.min.split("-")[1] == selected.max.split("-")[1] &&
                selected.min.split("-")[0] == selected.max.split("-")[0]
              ) {
                isChecked =
                  month === parseInt(selected.min.split("-")[1], 10) &&
                  year == parseInt(selected.min.split("-")[0], 10);
              }
            }
            if (isChecked && index > 2) {
              checkedCount += 1;
            }
            if (index <= 2 || isChecked || max) {
              if (value === "input") {
                if (selectedHelp.filter((el) => el.min).length > 0) {
                  isChecked = true;
                  isInput = true;
                  selectedHelp = selectedHelp.filter((el) => el.min)[0];
                }
                checkedCount += data.length;
              }

              return (
                <li className={styles.item} key={key}>
                  <Box>
                    <Checkbox
                      value={value}
                      defaultChecked={(isChecked && count !== 0) || (isChecked && min !== undefined)}
                      className={styles.checkbox}
                      size="small"
                      id={key}
                      onChange={
                        value !== "input"
                          ? onChange
                          : (event, value) => (!value ? onChangeInput(false) : setisInputState(!isInputState))
                      }
                      disabled={count === 0 && max === undefined}
                    />
                    {month ? (
                      <span className={count === 0 ? styles.disabledLabel : ""}>
                        {t(`offers_page.offers_list.month_${month}`)} {year}
                      </span>
                    ) : (
                      <span
                        className={
                          count === 0 && max === undefined ? styles.disabledLabel : max === undefined ? "" : styles.down
                        }
                      >
                        {label ? label : value.toString().replace(/_/g, " ")}{" "}
                        {max ? t("offers_page.offers_list.days") : ""}
                      </span>
                    )}
                  </Box>
                  <label htmlFor={key} title={value}>
                    {!!suffix && <span> {suffix}</span>}
                    {!!count && countOffers > 0 && <span className={styles.countOffers}>{countOffers}</span>}
                    {!!count && count > 0 && !max && (
                      <span className={`${styles.countOffers} ${styles.count}`}>{count}</span>
                    )}
                    {!!minPrice && (
                      <span className={`${styles.countOffers} ${styles.minPrice}`}>
                        {t("offers_page.filters.from") + " " + minPrice + currency}
                      </span>
                    )}
                  </label>
                  {value === "input" && (isInputState || isInput) && (
                    <Box className={styles.priceInput_Box}>
                      <form
                        className={styles.priceInput_container}
                        onSubmit={(event) => {
                          onChangeInput(true, selectedHelp, event);
                        }}
                      >
                        <label className={styles.priceInput_Label}>{t("offers_page.filters.from")}</label>
                        <input
                          defaultValue={inputValue.min == null && selected ? selectedHelp?.min : inputValue.min}
                          value={inputValue.min}
                          onChange={(event) =>
                            setInputValue({
                              min: event.target.value.replace(/\D/g, ""),
                              max: inputValue.max == null ? selectedHelp?.max : inputValue.max,
                            })
                          }
                        />
                        <label className={styles.priceInput_Label}>{t("offers_page.filters.to")}</label>
                        <input
                          defaultValue={inputValue.max == null && selected ? selectedHelp?.max : inputValue.max}
                          value={inputValue.max}
                          onChange={(event) =>
                            setInputValue({
                              max: event.target.value.replace(/\D/g, ""),
                              min: inputValue.min == null ? selectedHelp?.min : inputValue.min,
                            })
                          }
                        />
                        <Button className={styles.icon_button} type="submit">
                          <img src={icon} alt="Search" className={styles.searchIcon}></img>
                        </Button>
                      </form>
                      {error.status && <span className={styles.priceInput_Error}>{error.msg}</span>}
                    </Box>
                  )}
                </li>
              );
            } else if (isShowAll) {
              return (
                <li className={styles.item} key={key}>
                  <Box>
                    <Checkbox
                      value={value}
                      defaultChecked={isChecked}
                      className={styles.checkbox}
                      size="small"
                      id={key}
                      onChange={onChange}
                      disabled={count === 0 && max === undefined}
                    />

                    {month ? (
                      <span className={count === 0 ? styles.disabledLabel : ""}>
                        {t(`offers_page.offers_list.month_${month}`)} {year}
                      </span>
                    ) : (
                      <span className={count === 0 && max === undefined ? styles.disabledLabel : ""}>
                        {label ? label : value.toString().replace(/_/g, " ")} {max ? t("offers_page.filters.days") : ""}
                      </span>
                    )}
                  </Box>
                  <label htmlFor={key} title={value}>
                    {!!suffix && <span> {suffix}</span>}
                    {!!count && countOffers > 0 && <span className={styles.countOffers}>{countOffers}</span>}
                    {!!count && count > 0 && !max && (
                      <span className={`${styles.countOffers} ${styles.count}`}>{count}</span>
                    )}
                    {!!minPrice && (
                      <span className={`${styles.countOffers} ${styles.minPrice}`}>
                        {t("offers_page.filters.from") + minPrice + currency}
                      </span>
                    )}
                  </label>
                </li>
              );
            }
          })}
        </ul>
        {data.length > 3 + checkedCount && (
          <div>
            {isShowAll && (
              <p onClick={() => setIsShowAll(!isShowAll)} className={styles.showMoreLessToggle}>
                {t("offers_page.filters.show_less")}
              </p>
            )}
            {!isShowAll && (
              <p onClick={() => setIsShowAll(!isShowAll)} className={styles.showMoreLessToggle}>
                {t("offers_page.filters.show_all")} {data.length}
              </p>
            )}
          </div>
        )}
      </Box>
    </Box>
  );
};

export default CheckboxList;

import { useContext } from "react";

import { SINGLE_OFFER_PATH } from "config/routes";
import { SingleOfferContext } from "context/SingleOfferContext";
import { useTranslation } from "react-i18next";

import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import { Loader } from "components/Base";

import { Box } from "@mui/material";
import axios from "axios";
import { addDays, format } from "date-fns";
import { useEffect, useState } from "react";
import greenPricePrediction from "../../../images/icons/green-price-prediction-icon.svg";
import noDataIcon from "../../../images/icons/no-data-icon.svg";
import redPricePrediction from "../../../images/icons/red-price-prediction-icon.svg";
import { hasPermission } from "../../../utils/handlePermissions";
import Loading from "../../Base/Loading";
import styles from "./OfferTerms.module.scss";
import { useNavigate } from "react-router-dom";
import { generatePath } from "react-router";

const OfferTerms = ({ item_id, item_name, index, location_id }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { offer } = useContext(SingleOfferContext);
  const [page, setPage] = useState(0);
  const [termsPerPage, setTermsPerPage] = useState(5);
  const currency = window.localStorage.getItem("currency");

  const columns = [
    {
      id: t("offer_page.terms.term"),
      label: t("offer_page.terms.term"),
      align: "left",
    },
    {
      id: t("offer_page.terms.period"),
      label: t("offer_page.terms.period"),
      align: "left",
    },
    {
      id: t("offer_page.terms.price"),
      label: t("offer_page.terms.price"),
      align: "left",
    },
    {
      id: t("offer_page.terms.transport_type"),
      label: t("offer_page.terms.transport_type"),
      align: "left",
    },
    {
      id: t("offer_page.terms.price_prediction"),
      label: t("offer_page.terms.price_prediction"),
      align: "left",
    },
    {
      id: t("offer_page.terms.maintenances"),
      label: t("offer_page.terms.maintenances"),
      align: "right",
    },
    {},
  ];

  const terms = offer?.offer?.offerDetails?.terms || [];

  const [predictions, setPredictions] = useState({});
  const urlParams = new URLSearchParams(window.location.search);

  const handleCheckButtonClick = (termId, adults, children) => {
    navigate({
      pathname: generatePath(SINGLE_OFFER_PATH, { id: offer.offer.offerDetails.id }),
      search: urlParams.get("childrenBirthDates")
        ? `termId=${termId}&adults=${adults}&children=${children}&childrenBirthDates=${urlParams.get(
            "childrenBirthDates"
          )}`
        : `termId=${termId}&adults=${adults}&children=${children}`,

    });
  };

  useEffect(() => {
    if (!terms) {
      return;
    }
    let tripCodes = [];
    terms.forEach((term) => {
      tripCodes.push(term.tripCode);
    });

    if (hasPermission("prediction")) {
      //TODO: Move whole fetch login to another reusable component
      axios
        .post(process.env.REACT_APP_PREDICT_API_URL, { trip_codes: tripCodes })
        .then((data) => {
          const predictionsTmp = {};
          terms.forEach((term) => {
            if (
              !term.tripCode &&
              !data?.data?.forecasts?.[term.tripCode] &&
              Object.keys(data.data.forecasts).length < 1
            ) {
              return;
            }
            const price = term.personPrices?.[0]?.price;
            const forecast = Object.entries(data.data.forecasts).reduce(
              (acc, [key, value]) => ({
                ...acc,
                [key.replace(/-/g, "")]: value,
              }),
              {}
            )[term.tripCode];
            if (term && term.tripCode) {
              const tripCode = term.tripCode;
              if (forecast) {
                const dates = Object.keys(forecast);
                const prices = Object.values(forecast);
                const lastPredictionDate = dates[dates.length - 1];
                const lastPredictionPrice = prices[prices.length - 1];
                if (lastPredictionPrice > price) {
                  predictionsTmp[tripCode] = { state: "up", price: lastPredictionPrice, date: lastPredictionDate };
                } else if (lastPredictionPrice < price) {
                  predictionsTmp[tripCode] = { state: "down", price: lastPredictionPrice, date: lastPredictionDate };
                } else {
                  predictionsTmp[tripCode] = { state: "same", price: lastPredictionPrice, date: lastPredictionDate };
                }
              } else {
                predictionsTmp[tripCode] = { state: "error" };
              }
            }
          });
          setPredictions(predictionsTmp);
        })
        .catch((error) => {
          console.log(error);
          const predictionsTmp = {};
          terms.forEach((term) => {
            predictionsTmp[term.id] = { state: "error" };
          });
          setPredictions(predictionsTmp);
        });
    }
  }, [terms]);

  if (offer.offer === undefined) {
    return <Loader />;
  }
  return (
    <div className={styles.termContainer}>
      <div className={styles.offerTerms} id="terms">
        <TableContainer component={Paper}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) =>
                  !hasPermission("prediction") && column.label == t("offer_page.terms.price_prediction") ? null : (
                    <TableCell key={column.id} align={column.align} style={{ fontFamily: "Silka" }}>
                      {column.label}
                    </TableCell>
                  )
                )}
              </TableRow>
            </TableHead>
            <TableBody style={{ fontFamily: "Silka" }}>
              {terms.slice(page * termsPerPage, page * termsPerPage + termsPerPage).map((term, index) => {
                return (
                  <TableRow key={index} className={styles.tableRow}>
                    <TableCell align="center" style={{ fontFamily: "Silka" }} align="left">
                      {term.departureDate} -{" "}
                      {format(new Date(addDays(new Date(term.departureDate), term.period).toISOString()), "yyyy-MM-dd")}
                    </TableCell>
                    <TableCell align="center" style={{ fontFamily: "Silka" }} align="left">
                      {term.period}
                    </TableCell>
                    <TableCell align="center" style={{ fontFamily: "Silka" }} align="left">{`${
                      term && term.personPrices && term.personPrices[0].price
                        ? term.personPrices[0].price.toFixed(2)
                        : term.basePrice.toFixed(2)
                    } ${currency}`}</TableCell>
                    <TableCell align="center" style={{ fontFamily: "Silka" }} align="left">
                      {term.transportType === "own_transport"
                        ? t("offers_page.filters.transport_type.own_transport")
                        : term.transportType}
                    </TableCell>
                    {hasPermission("prediction") && predictions[term.tripCode] && (
                      <TableCell align="center" style={{ fontFamily: "Silka", maxWidth: "250px" }} align="right">
                        <div className={styles.pricePredictionBox}>
                          {predictions[term.tripCode].state === "down" && (
                            <>
                              <img
                                src={redPricePrediction}
                                height="30"
                                alt="Price prediction Icon"
                                className={styles.pricePredictionIcon}
                              />
                              {t("offers_page.offers_list.price_prediction_negative_info")}
                            </>
                          )}
                          {predictions[term.tripCode].state === "up" && (
                            <>
                              <img
                                src={greenPricePrediction}
                                height="30"
                                alt="Price prediction Icon"
                                className={styles.pricePredictionIcon}
                              />
                              {t("offers_page.offers_list.price_prediction_positive_info")}
                            </>
                          )}
                          {predictions[term.tripCode].state === "same" && (
                            <>
                              <img
                                src={noDataIcon}
                                height="30"
                                alt="Price prediction Icon"
                                className={styles.pricePredictionIcon}
                              />
                              {t("offers_page.offers_list.price_prediction_same_price_info")}
                            </>
                          )}
                          {predictions[term.tripCode].state === "error" && (
                            <>
                              <img
                                src={noDataIcon}
                                height="30"
                                alt="Price prediction Icon"
                                className={styles.pricePredictionIcon}
                              />
                              {t("offers_page.offers_list.price_prediction_no_data_info")}
                            </>
                          )}
                          {predictions[term.tripCode].state === "loading" && (
                            <Box sx={{ mt: "20px", mr: "10px" }}>
                              <Loading size={25} color="secondary" />
                            </Box>
                          )}
                        </div>
                      </TableCell>
                    )}
                    <TableCell align="center" style={{ fontFamily: "Silka" }} align="right">
                      {term.maintenances[0].name}
                    </TableCell>
                    <TableCell>
                      <button
                        onClick={() =>
                          handleCheckButtonClick(
                            term.id,
                            term.personsConfiguration.adults,
                            term.personsConfiguration.children
                          )
                        }
                      >
                        {t("offers_page.check")}
                      </button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};
export default OfferTerms;

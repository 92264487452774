import React from "react";
import { createSvgIcon } from "@mui/material/utils";

export default createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" id="expand-more">
    <path fill="none" d="M24 24H0V0h24v24z" opacity=".87"></path>
    <path d="M15.88 9.29L12 13.17 8.12 9.29c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41l4.59 4.59c.39.39 1.02.39 1.41 0l4.59-4.59c.39-.39.39-1.02 0-1.41-.39-.38-1.03-.39-1.42 0z"></path>
  </svg>,
  "ExpandMoreArrow"
);

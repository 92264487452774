import { useQuery } from "@apollo/client";
import useAuth from "./useAuth";
import { hasPermission } from "../utils/handlePermissions";
import { getOffers } from "../GraphQL/GetOffers";
import { getPartnerOffers } from "../GraphQL/GetPartnerOffers";

const useGetOffers = (filters, sort, page) => {
  let filtersFormatted = JSON.parse(JSON.stringify(filters));
  if (filtersFormatted.propertyCategory) {
    let tmp = [];
    filtersFormatted.propertyCategory.map((item) => {
      tmp = [...tmp, ...item.split("_")];
    });
    filtersFormatted.propertyCategory = tmp;
  }
  const auth = useAuth();

  const getQuery = () => {
    if (hasPermission("has_semantic_response")) {
      return getOffers(filtersFormatted);
    } else {
      return getPartnerOffers(filtersFormatted);
    }
  };

  const { error, loading, data } = useQuery(getQuery(), {
    skip: !auth.isAuthenticated,
    variables: {
      countryCode: window.localStorage.getItem("countryCode"),
      languageCode: window.localStorage.getItem("languageCode"),
      filters: filtersFormatted,
      order: sort ? [sort] : ["SCORE_DESC"],
      pagination: { offset: ((page || 1) - 1) * 10, limit: 10 },
    },
    nextFetchPolicy: "cache-first",
  });

  return { error, loading, data };
};

export default useGetOffers;

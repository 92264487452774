import React from "react";
import { Alert, Box, Stack, Typography } from "@mui/material";

const Error = ({ error }) => {
  return (
    <Stack width="100%">
      <Alert severity="error">
        <Typography as="h3" fontWeight={"bold"} mb={5}>
          {error.networkError?.name} - Status Code {error.networkError?.statusCode}
        </Typography>

        <Box mb={4}>GraphQLErrors: {JSON.stringify(error.graphQLErrors)}</Box>

        <Box mb={4} bgcolor="#fff6f7" p={2} color="#000">
          {JSON.stringify(error.networkError?.bodyText)}
        </Box>
      </Alert>
    </Stack>
  );
};

export default Error;

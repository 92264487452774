import React, { useEffect, useState } from "react";

import { Box, Typography, Popover, Stack } from "@mui/material";
import { Man, Children, ArrowExpand } from "components/Icons";

import { PERSON_TYPE_ADULTS, PERSON_TYPE_CHILDREN } from "config/consts";

import Button from "components/Base/Button";
import PlusMinus from "./PlusMinus";
import { DesktopDatePicker, LocalizationProvider } from "@mui/lab";
import TextField from "@material-ui/core/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";

const CHILDREN_MAX_LIMIT = 6;
const ADULTS_MAX_LIMIT = 30;

const CHILDREN_MIN_LIMIT = 0;
const ADULTS_MIN_LIMIT = 1;

const Travelers = ({
  adults = parseInt(window.localStorage.getItem("adults")) || 2,
  children = 0,
  childrenBirthDatesArr = [],
  onChange,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [adultsCount, setAdultsCount] = useState(adults);
  const [childrenCount, setChildrenCount] = useState(children);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [childrenBirthDates, setChildrenBirthDates] = useState(childrenBirthDatesArr || []);
  const { t } = useTranslation();

  const handleChange = (newValue, index) => {
    setChildrenBirthDates((previousValue) => {
      previousValue[index] = format(new Date(newValue), "yyyy-MM-dd");
      return [...previousValue];
    });
  };

  useEffect(() => {
    setAdultsCount(adults);
  }, [adults]);

  useEffect(() => {
    setChildrenCount(children);
  }, [children]);

  useEffect(() => {
    setChildrenBirthDates(childrenBirthDates);
  }, [childrenBirthDates]);

  useEffect(() => {
    if (childrenCount !== childrenBirthDates.length) {
      setChildrenBirthDates(new Array(childrenCount).fill(null));
    }
  }, [childrenCount]);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleClickReady = () => {
    let isEmpty = false;
    childrenBirthDates.map((item) => {
      if (!item) isEmpty = true;
    });
    setIsSubmitted(true);
    if (isEmpty) {
      return;
    }
    handleClosePopover();
    onChange({ adults: adultsCount, children: childrenCount, childrenBirthDates: childrenBirthDates });
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const handleClickTravelers = (event) => {
    setAdultsCount(adults);
    setChildrenCount(children);
    setAnchorEl(event.currentTarget);
    setIsSubmitted(false);
  };

  const handleChangePlus = (persontType) => {
    if (persontType === PERSON_TYPE_ADULTS && adultsCount < ADULTS_MAX_LIMIT) {
      setAdultsCount(() => adultsCount + 1);
    }
    if (persontType === PERSON_TYPE_CHILDREN && childrenCount < CHILDREN_MAX_LIMIT) {
      setChildrenCount(() => childrenCount + 1);
    }
  };

  const handleChangeMinus = (persontType) => {
    if (persontType === PERSON_TYPE_ADULTS && adultsCount > ADULTS_MIN_LIMIT) {
      setAdultsCount(() => adultsCount - 1);
    }
    if (persontType === PERSON_TYPE_CHILDREN && childrenCount > CHILDREN_MIN_LIMIT) {
      setChildrenCount(() => childrenCount - 1);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box mb={4}>
        <Typography as="h3" color="#000" fontFamily="Sharp Grotesk Medium" fontSize="16px" fontWeight="bold" mb={1}>
          {t("offers_page.filters.travelers")}
        </Typography>
        <Box mt={2}>
          <Button
            aria-describedby={id}
            onClick={handleClickTravelers}
            variant="outlined"
            color="primary"
            sx={{
              color: "#000",
              borderColor: "#c4c4c4",
              borderRadius: "5px",
              justifyContent: "flex-start",
              padding: "4px 12px 4px 4px",
              position: "relative",
              ".MuiButton-endIcon": {
                marginLeft: 3,
              },
            }}
            endIcon={<ArrowExpand sx={{ width: "0.8em !important", pointerEvents: "none", alignSelf: "flex-end" }} />}
          >
            <Man />
            <Typography fontSize="20px" mr={2}>
              {adultsCount}
            </Typography>
            <Children />
            <Typography fontSize="20px">{childrenCount}</Typography>
          </Button>
          <Popover
            id={id}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClosePopover}
            sx={{
              boxShadow: 0,
              borderRadius: 2,
              p: 2,
            }}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            <Box width="280px" p={2.5}>
              <PlusMinus
                personType={PERSON_TYPE_ADULTS}
                personCount={adultsCount}
                onChangePlus={handleChangePlus}
                onChangeMinus={handleChangeMinus}
              />
              <PlusMinus
                personType={PERSON_TYPE_CHILDREN}
                personCount={childrenCount}
                onChangePlus={handleChangePlus}
                onChangeMinus={handleChangeMinus}
              />
              {new Array(childrenCount).fill("").map((tmp, index) => (
                <Box mt={2} key={index}>
                  <DesktopDatePicker
                    sx={{ mt: 4 }}
                    label={t("offers_page.filters.date_of_birth")}
                    inputFormat="MM/dd/yyyy"
                    disableFuture
                    value={childrenBirthDates[index]}
                    onChange={(value) => handleChange(value, index)}
                    renderInput={(params) => (
                      <TextField {...params} error={isSubmitted && !childrenBirthDates[index]} />
                    )}
                  />
                </Box>
              ))}
              <Box display="flex" justifyContent="space-between" mt={2} pt={4}>
                <Button
                  variant="text"
                  fullWidth={true}
                  onClick={handleClosePopover}
                  sx={{ mr: 1, fontSize: "0.75rem" }}
                >
                  {t("offers_page.filters.clear")}
                </Button>
                <Button fullWidth={true} onClick={handleClickReady} sx={{ fontSize: "0.75rem" }}>
                  {t("offers_page.filters.confirm")}
                </Button>
              </Box>
            </Box>
          </Popover>
        </Box>
      </Box>
    </LocalizationProvider>
  );
};

export default Travelers;

import { Box, Modal, Typography } from "@mui/material";
import { useState } from "react";

import PocketBase from "pocketbase";
import { useTranslation } from "react-i18next";
import useAuth from "../../../../hooks/useAuth";
import rate1 from "../../../../images/rate1.png";
import rate2 from "../../../../images/rate2.png";
import rate3 from "../../../../images/rate3.png";
import rate4 from "../../../../images/rate4.png";
import { hasPermission } from "../../../../utils/handlePermissions";
import Button from "../../../Base/Button";
import styles from "./Evaluation.module.scss";

const Evaluation = ({ id, index, page, count, query, queryId }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const partner = window.localStorage.getItem("partnerSlug");
  const { user } = useAuth();

  const postRate = (rating) => {
    const pb = new PocketBase(process.env.REACT_APP_POCKETBASE_URL);
    const data = {
      query: query,
      queryId: queryId,
      offer: id,
      ranking: (page - 1) * 10 + index + 1,
      count: count,
      rating: rating,
      partner: partner,
      email: user.email,
    };
    pb.collection("marks")
      .create(data)
      .then(() => {
        handleOpen();
      });
  };

  return (
    <>
      {hasPermission("can_evaluate") && (
        <Box className={styles.ratesWrapper}>
          <Box className={styles.rate}>
            <img onClick={() => postRate(1)} src={rate1} />
            <span>{t("offers_page.badly")}</span>
          </Box>
          <Box className={styles.rate}>
            <img onClick={() => postRate(2)} src={rate2} />
            <span>{t("offers_page.middling")}</span>
          </Box>
          <Box className={styles.rate}>
            <img onClick={() => postRate(3)} src={rate3} />
            <span>{t("offers_page.ok")}</span>
          </Box>
          <Box className={styles.rate}>
            <img onClick={() => postRate(4)} src={rate4} />
            <span>{t("offers_page.super")}</span>
          </Box>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box className={styles.popup}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                {t("offers_page.thankYouForRating")}
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                {t("offers_page.informationHasBeenSent")}
              </Typography>
              <Button sx={{ mt: 4, fontSize: "0.75rem" }} onClick={handleClose}>
                {t("offers_page.close")}
              </Button>
            </Box>
          </Modal>
        </Box>
      )}
    </>
  );
};

export default Evaluation;

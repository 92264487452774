import { Button } from "components/Base";
import { useEffect, useState } from "react";
import { useQueryParam, StringParam } from "use-query-params";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import step_1 from "../../../images/onboarding/step_1.png";
import step_5 from "../../../images/onboarding/step_5.png";
import styles from "./Onboarding.module.scss";
import { useNavigate } from "react-router-dom";
import { QTRAVELAI } from "config/routes";

const Onboarding = ({ close }) => {
  const navigate = useNavigate();

  const { t } = useTranslation();
  const [step, setStep] = useState(parseInt(sessionStorage.getItem("OnBoarding_step")) || 0);
  const [showOverlay, setShowOverlay] = useState(true);
  const user = window.localStorage.getItem("email");
  const [exampleQuery, setQuery] = useQueryParam("query", StringParam);
  const setSearchText = (e) => setQuery(_.trim(decodeURIComponent(escape(e)), "\n"));

  const handleNext = async () => {
    if (step == 0) {
      setSearchText(JSON.parse(localStorage.getItem("query_examples"))[0].example);
      setStep(step + 1);
      // await
      sessionStorage.setItem("OnBoarding_step", step);
      window.dispatchEvent(new Event("storage"));
      setTimeout(() => {
        navigate(`/offers${window.location.search}`);
      }, 1);
    }
    if (step < ONBOARDING_DATA.length - 1) {
      setStep(step + 1);
    }
    window.scrollTo({ top: ONBOARDING_DATA[step + 1].height || 0, behavior: "smooth" });
  };

  const handleBack = () => {
    if (step > 0) {
      setStep(step - 1);
    }
    window.scrollTo({ top: ONBOARDING_DATA[step - 1].height || 0, behavior: "smooth" });
  };

  const ONBOARDING_DATA = [
    {
      class: "onboarding_popup",
      image: step_1,
      title: t("onboarding.step1.hello"),
      text: t("onboarding.step1.description"),
      question: t("onboarding.step1.question"),
      next: t("onboarding.step1.button2"),
      back: t("onboarding.step1.button1"),
    },
    {
      height: 300,
      class: "onboarding_popup_step2",
      text: t("onboarding.step2.description"),
      next: t("onboarding.step2.button2"),
      back: t("onboarding.step2.button1"),
    },
    {
      height: 850,
      class: "onboarding_popup_step3",
      text: t("onboarding.step3.description"),
      next: t("onboarding.step3.button2"),
      back: t("onboarding.step3.button1"),
    },
    {
      height: 2400,
      class: "onboarding_popup_step4",
      text: t("onboarding.step4.description"),
      next: t("onboarding.step4.button2"),
      back: t("onboarding.step4.button1"),
    },
    {
      class: "onboarding_popup_step5",
      text: t("onboarding.step5.description"),
      next: t("onboarding.step5.button2"),
      back: t("onboarding.step5.button1"),
    },
    {
      class: "onboarding_popup_step6",
      text: t("onboarding.step6.description"),
      next: t("onboarding.step6.button2"),
      back: t("onboarding.step6.button1"),
    },
    {
      class: "onboarding_popup",
      image: step_5,
      question2: t("onboarding.step7.question2"),
      question: t("onboarding.step7.question"),
      back: t("onboarding.step7.button2"),
    },
  ];

  if (step == 6) {
    sessionStorage.setItem("onboarding", true);
  }

  useEffect(() => {
    sessionStorage.setItem("OnBoarding_step", step);
    window.dispatchEvent(new Event("storage"));
  }, [step]);

  const clickHandler = (event) => {
    event.stopPropagation();
  };

  return (
    <div className={styles.container}>
      {showOverlay && <div className={styles.overlay} onClick={clickHandler}></div>}
      <div className={styles[ONBOARDING_DATA[step].class]}>
        {step < ONBOARDING_DATA.length && (
          <div
            className={
              ONBOARDING_DATA[step].class == "onboarding_popup" ? styles.onboarding_box : styles.onboarding_box_steps
            }
          >
            {ONBOARDING_DATA[step].image ? <img src={ONBOARDING_DATA[step].image} alt="Onboarding" /> : null}
            {step == 0 && (
              <h1>
                {ONBOARDING_DATA[step].title} {user}!
              </h1>
            )}
            <p>{ONBOARDING_DATA[step].text}</p>
            {step == 6 ? (
              <p>
                {ONBOARDING_DATA[step].question2}
                <a target="_blank" href={`${QTRAVELAI}${t("url.contact")}/#cf`}>
                  {ONBOARDING_DATA[step].question}
                </a>
              </p>
            ) : (
              <p>{ONBOARDING_DATA[step].question}</p>
            )}
          </div>
        )}
        <div className={ONBOARDING_DATA[step].class == "onboarding_popup" ? null : styles.onboarding_button_box}>
          {step != 6 && step != 0 && (
            <Button onClick={close} className={styles.closeButton} id={ONBOARDING_DATA[step].back}>
              {t("onboarding.close")}
            </Button>
          )}
          <Button
            onClick={step === 0 || step === 6 ? close : handleBack}
            className={styles.backButton}
            id={ONBOARDING_DATA[step].back}
          >
            {ONBOARDING_DATA[step].back}
          </Button>

          {step < 6 && (
            <Button onClick={handleNext} className={styles.nextButton} id={ONBOARDING_DATA[step].next}>
              {ONBOARDING_DATA[step].next}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Onboarding;

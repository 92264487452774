import { Box, Container, Typography } from "@mui/material";
import { COMPANY_NAME, COMPANY_NAME_PL } from "config/consts";
import FE_logos_pl from "images/FE_logos.jpg";
import FE_logos from "images/FE_logos.png";
import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./Footer.module.scss";

const FooterText = () => {
  const { t, i18n } = useTranslation();

  const lang = i18n.language;
  return (
    <Box bgcolor="#FFF" color="#000">
      <Container maxWidth="lg">
        <Box className={styles.footerWrapper}>
          <Box className={styles.footerProjectDesc}>
            <Typography fontSize="14px">{t("shared.footer_projekt")}</Typography>
          </Box>
          <Box className={styles.footerLogos}>
            <img src={lang === "en" ? FE_logos : FE_logos_pl} alt="Logos" />
          </Box>
        </Box>
        <Box pb={4} className={styles.footerCompanyName}>
          <Typography fontSize="14px">{lang === "en" ? COMPANY_NAME : COMPANY_NAME_PL}</Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default FooterText;

import React from "react";

import { useTranslation } from "react-i18next";
import { Box, FormControl, MenuItem, Select } from "@mui/material";
import styles from "../../pages/Offers/Offers.module.scss";

const Sorting = ({ sort, setSort }) => {
  const { t } = useTranslation();

  const handleChange = (event) => {
    setSort({ sort: event.target.value }, "pushIn");
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
      <span style={{ marginRight: "20px" }}>{t("offers_page.offers_list.sort_by")}:</span>
      <div>
        <FormControl sx={{ minWidth: 130 }} size="small">
          <Select className={styles.sort} value={sort.sort} onChange={handleChange}>
            <MenuItem value={"SCORE_DESC"}>{t("offers_page.offers_list.relevance")}</MenuItem>
            <MenuItem value={"PRICE_ASC"}>{t("offers_page.offers_list.lowest_price")}</MenuItem>
            <MenuItem value={"PRICE_DESC"}>{t("offers_page.offers_list.highest_price")}</MenuItem>
            <MenuItem value={"DEPARTURE_DATE_ASC"}>{t("offers_page.offers_list.from_the_earliest_date")}</MenuItem>
            <MenuItem value={"DEPARTURE_DATE_DESC"}>{t("offers_page.offers_list.from_the_latest_date")}</MenuItem>
          </Select>
        </FormControl>
      </div>
    </Box>
  );
};

export default Sorting;

export const HOME_PATH = "/";
export const OFFERS_PATH = "/offers";
export const LOGIN_PATH = "/login";
export const SINGLE_OFFER_PATH = "/offer/:id";
export const UNAUTHORIZED_PATH = "/401";
export const NOTFOUND_PATH = "/404";

export const DEMO_DASHBOARD = "https://dashboard.qtravel.ai/";
export const QTRAVELAI = "https://qtravel.ai";

export const QTRAVELAI_CONTACT = "https://www.qtravel.ai/contact/";

export const THEME_CONFIG = {
  palette: {
    primary: {
      main: "#0B4356",
    },
    secondary: {
      main: "#FF7427",
    },
    white: {
      main: "#FFF",
    },
  },
  typography: {
    fontFamily: "Silka",
  },
  components: {
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: "#000",
        },
      },
    },
    MuiIcon: {
      defaultProps: {
        baseClassName: "qtravel",
      },
      styleOverrides: {
        root: {
          width: "auto",
          height: "auto",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          padding: "3px 15px !important",
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          fontFamily: "Silka",
          fontWeight: 400,
          borderRadius: "16px",
          border: "1px solid #E5E5E5",
          boxSizing: "border-box",
          boxShadow: "0px 0px 25px rgba(0, 0, 0, 0.1)",
          marginTop: "-4px",
          marginLeft: "-3px",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: "Silka",
          fontWeight: 400,
          borderRadius: "30px",
          paddingTop: "10px",
          paddingBottom: "10px",
        },
      },
    },
  },
};

import { gql } from "@apollo/client";
import { countryFacets } from "./GetOffers";

export function getPartnerOffers(filters) {
  return gql`
  query GetOffers(
    $countryCode: Country!
    $languageCode: Language!
    $filters: Filters!
    $order: [Order!]
    $pagination: Pagination
  ) {
    search(
      countryCode: $countryCode
      languageCode: $languageCode
      filters: $filters
      order: $order
      pagination: $pagination
    ) {
      count
      totalTripCount
      facets {
        ${countryFacets(filters)}
        departureCity {
          count
          value
        }
        departureDate {
          min
          max
        }
        transportType {
          count
          value
        }
        maintenanceName {
          count
          value
        }
        periodRange {
          max
          min
        }
        price {
          max
          min
        }
        propertySourceCategory {
          count
          value
        }
        adults {
          count
          value
        }
        semanticCategoryAccommodation {
          count
          value
        }
        semanticCategoryActivity {
          count
          value
        }
        semanticCategoryBoardType {
          count
          value
        }
        semanticCategoryCuisine {
          count
          value
        }
        semanticCategoryHotelAmenities {
          count
          value
        }
        semanticCategoryHotelAttractions {
          count
          value
        }
        semanticCategoryHotelType {
          count
          value
        }
        semanticCategoryLocalization {
          count
          value
        }
        semanticCategoryNature {
          count
          value
        }
        semanticCategoryOfferType {
          count
          value
        }
        semanticCategoryOther {
          count
          value
        }
        semanticCategoryRoomAmenities {
          count
          value
        }
        semanticCategoryRoomType {
          count
          value
        }
        semanticCategorySkiing {
          count
          value
        }
        semanticCategoryTransportation {
          count
          value
        }
        semanticCategoryTripAttractions {
          count
          value
        }
        semanticCategoryTripType {
          count
          value
        }
      }
      offers {
        id
        name
        cities {
          sourceName
        }
        countries {
          sourceName
        }
        regions {
          sourceName
        }
        images {
          original
        }
        property {
          sourceCategory
          category
        }
        terms {
          departureDate
          departureCity
          rooms {
            code
            description
            sourceCode
            sourceName
          }
          personsConfiguration {
            adults
            children
          }
          personPrices {
            age
            ageRange
            price
            type
          }
          maintenances {
            sourceCode
            sourceName
            name
          }
          period
          transportType
        }
        detectedSemanticCategories {
          accommodation
          activity
          boardType
          cuisine
          hotelAmenities
          hotelAttractions
          hotelType
          localization
          nature
          offerType
          other
          roomAmenities
          roomType
          skiing
          transportation
          tripAttractions
          tripType
        }
        semanticCategories {
          accommodation
          activity
          boardType
          cuisine
          hotelAmenities
          hotelAttractions
          hotelType
          localization
          nature
          offerType
          other
          roomAmenities
          roomType
          skiing
          transportation
          tripAttractions
          tripType
        }
      }
    }
  }
`;
}

import { gql } from "@apollo/client";

export const GET_SIMILAR_OFFERS = gql`
  query SimilarOffers($countryCode: Country!, $languageCode: Language!, $id: ID!, $limit: Int) {
    similarOffers(countryCode: $countryCode, languageCode: $languageCode, id: $id, limit: $limit) {
      offers {
        id
        name
        cities {
          sourceName
        }
        countries {
          sourceName
        }
        regions {
          sourceName
        }
        property {
          category
          sourceCategory
        }
        images {
          original
        }
        terms {
          price {
            min
            max
            average
            median
          }
          maintenances {
            codes
            sourcesCodes
            names
          }
        }
      }
    }
  }
`;

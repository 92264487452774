import { useEffect, useState } from "react";

import TagManager from "react-gtm-module";
import { useTranslation } from "react-i18next";

import { useQuery } from "@apollo/client";
import { Box, Typography, Tooltip } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { Button, DatePickerRange } from "components/Base";
import { format } from "date-fns";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { JsonParam, NumberParam, ObjectParam, useQueryParams, withDefault, StringParam } from "use-query-params";
import { GET_FILTERS } from "../../GraphQL/GetFilters";
import { ArrowExpand } from "../Icons";
import Travelers from "../SidebarFilters/Travelers";
import styles from "./ClassicSearchForm.module.scss";
import { ClassicSearchIcon } from "../Icons";
import useSnowplowAnalytics from "../../hooks/useSnowplowAnalytics";
import { SCHEMA_SEARCH } from "../../config/snowplow";
import { hasPermission } from "../../utils/handlePermissions";
const ClassicSearchForm = ({ goToOffers, classicSearch, setClassicSearch }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const [searchFilters, setSearchFilters] = useQueryParams({
    adults: withDefault(NumberParam, 2),
    children: withDefault(NumberParam, 0),
    childrenBirthDates: JsonParam,
    countrySourceName: JsonParam,
    departureDate: ObjectParam,
    query: StringParam,
  });

  const [departureDate, setDepartureDate] = useState(undefined);
  const [country, setCountry] = useState("");
  const [adults, setAdults] = useState(2);
  const [children, setChildren] = useState(0);
  const [childrenBirthDates, setChildrenBirthDates] = useState([]);

  useEffect(() => {
    setDepartureDate(searchFilters.departureDate || undefined);
    setCountry(searchFilters.countrySourceName || undefined);
    setAdults(searchFilters.adults || 2);
    setChildren(searchFilters.children || 0);
    setChildrenBirthDates(searchFilters.childrenBirthDates || []);
  }, [searchFilters]);

  let countryOptions = [];

  const { data } = useQuery(GET_FILTERS, {
    variables: {
      countryCode: window.localStorage.getItem("countryCode"),
      languageCode: window.localStorage.getItem("languageCode"),
      filters: { adults: 2 },
    },
  });

  if (
    !!data &&
    data.search &&
    data.search.facets &&
    data.search.facets.countrySourceName &&
    data.search.facets.countrySourceName.length > 0
  ) {
    countryOptions = data.search.facets.countrySourceName.map((item) => ({ value: item.value, label: item.value }));
  }

  const onChangeDates = () => {
    return function (event) {
      setDepartureDate({ min: event[0], max: event[1] });
    };
  };

  const onChangeCountry = () => {
    return function (event, item) {
      setCountry(item.value);
    };
  };

  const onChangeTravellers = () => {
    return function (event) {
      setAdults(event.adults);
      setChildren(event.children);
      setChildrenBirthDates(event.childrenBirthDates);
    };
  };

  const search = () => {
    const param = new URLSearchParams(window.location.search);
    goToOffers(param.get("query") ? param.get("query") : "");
    setSearchFilters(
      {
        countrySourceName: country,
        departureDate: departureDate,
        adults: adults,
        children: children,
        childrenBirthDates: childrenBirthDates,
        query: param.get("query"),
      },
      "pushIn"
    );
    if (location.pathname.indexOf("offer") === -1) {
      let search = `?adults=${adults}&children=${children}`;
      if (children > 0) {
        search = `${search}&childrenBirthDates=${encodeURIComponent(JSON.stringify(childrenBirthDates))}`;
      }
      if (country) {
        search = `${search}&countrySourceName=${encodeURIComponent(JSON.stringify(country))}`;
      }
      if (departureDate) {
        search = `${search}&departureDate=min-${departureDate.min}_max-${departureDate.max}`;
      }
      if (param.get("query")) {
        search = `${search}&query=${param.get("query")}`;
      }
      navigate({
        pathname: "/offers",
        search: search,
      });
    }
  };
  const analytics = useSnowplowAnalytics();

  return (
    <Box className={styles.searchBoxContainer} display={"flex"}>
      <Box className={styles.searchBox}>
        <Box>
          <Typography as="h3" color="#000" fontFamily="Sharp Grotesk Medium" fontSize="16px" fontWeight="bold" mb={2}>
            {t("offers_page.filters.destination")}
          </Typography>
          <Autocomplete
            disablePortal
            options={countryOptions}
            sx={{ width: 300 }}
            onChange={onChangeCountry()}
            value={country}
            isOptionEqualToValue={(option, value) => option.value === value}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("offers_page.filters.country")}
                InputLabelProps={{
                  shrink: true,
                }}
                placeholder={t("offers_page.filters.classic_search_country_placeHolder")}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: <ArrowExpand sx={{ width: "0.64em !important", pointerEvents: "none" }} />,
                }}
              />
            )}
          />
        </Box>
        <Box>
          <DatePickerRange
            title={t("offers_page.filters.dates")}
            minDate={format(new Date(), "yyyy-MM-dd")}
            initialDates={departureDate}
            onChange={onChangeDates()}
            displayStyle={"row"}
            sx={{ display: "flex" }}
          />
        </Box>
        <Travelers
          onChange={onChangeTravellers()}
          adults={adults}
          children={children}
          childrenBirthDatesArr={childrenBirthDates}
        />
      </Box>
      {hasPermission("classic_hybrid_search") && (
        <Box className={styles.classicSearch_container}>
          <a style={{ color: "#0b4356" }}>{t("main.go_back_classic_search")}</a>
          <Button className={styles.classicSearch_button} onClick={() => setClassicSearch(false)}>
            <ClassicSearchIcon></ClassicSearchIcon>
          </Button>
        </Box>
      )}
      <Button
        type="button"
        onClick={() => {
          search();
          TagManager.dataLayer({
            dataLayer: {
              event: "search",
              language: window.localStorage.getItem("i18nextLng"),
              userProject: window.localStorage.getItem("partnerSlug"),
              userId: window.localStorage.getItem("email").replace("@qtravel.ai", ""),
            },
          });
          analytics.track("search", {
            contexts: [
              {
                schema: SCHEMA_SEARCH,
                data: {
                  event: "search",
                  language: window.localStorage.getItem("i18nextLng"),
                  userProject: window.localStorage.getItem("partnerSlug"),
                  userId: window.localStorage.getItem("email").replace("@qtravel.ai", ""),
                },
              },
            ],
          });
        }}
        sx={{
          mx: 4,
          fontSize: "0.75rem",
          marginTop: !hasPermission("classic_hybrid_search") ? "40px !important" : null,
        }}
        className={styles.searchButton}
        id={t("id.button_main_search_id_classic")}
      >
        {t("main.form_button")}
      </Button>
    </Box>
  );
};

export default ClassicSearchForm;

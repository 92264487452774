import { Box, Modal, Typography } from "@mui/material";
import Button from "components/Base/Button";
import styles from "./Popup.module.scss";

const Popup = ({ title, button, handleClose, open, onClick }) => {
  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className={styles.popup}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {title}
          </Typography>
          <Button sx={{ mt: 4, fontSize: "0.75rem" }} onClick={onClick}>
            {button}
          </Button>
        </Box>
      </Modal>
    </>
  );
};
export default Popup;

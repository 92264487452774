import { useEffect } from "react";
import Analytics from "analytics";
import snowplowPlugin from "@analytics/snowplow";

const useSnowplowAnalytics = () => {
  const analytics = Analytics({
    app: "demo",
    plugins: [
      snowplowPlugin({
        name: "snowplow",
        collectorUrl: process.env.REACT_APP_SNOWPLOW_COLLECTOR_URL,
        trackerSettings: {
          appId: "demo",
        },
      }),
    ],
  });

  useEffect(() => {
    analytics.on("initialize:snowplow", ({ instance }) => {
      instance.plugins.snowplow.enableActivityTracking(10, 10);
      instance.plugins.snowplow.enableLinkClickTracking();
    });
  }, []);

  return analytics;
};

export default useSnowplowAnalytics;

import { Box } from "@mui/material";

import luggage from "images/lugagge-search.png";
import men from "images/search_men.png";
import menDark from "images/men-laptop.png";
import womenWave from "images/women-wave.png";
import womenLugagge from "images/women-search.png";

export const HOME_PAGE_THEMES = [
  {
    background: { bgcolor: "#F7A0CA", color: "#0b4356" },
    boxImage: (
      <Box display="flex" mb={-5}>
        <img src={men} alt="Men" />
      </Box>
    ),
  },
  {
    background: { bgcolor: "#00B3F9", color: "#FFF" },
    boxImage: (
      <Box display="flex" mb={-10} maxWidth="460px">
        <img src={womenWave} alt="Women" />
      </Box>
    ),
  },
  {
    background: { bgcolor: "#F7A0CA", color: "#FFF" },
    boxImage: (
      <Box display="flex" mt={6} mb={-5} mr={3}>
        <img src={menDark} alt="Men" />
      </Box>
    ),
  },
  {
    background: { bgcolor: "#FFB300", color: "#0b4356" },
    boxImage: (
      <Box display="flex" mt={6} mb={-20} mr={-5} sx={{ maxWidth: ["300px", "400px"] }}>
        <img src={luggage} alt="Lugagge" />
        <img src={womenLugagge} alt="Women" />
      </Box>
    ),
  },
];

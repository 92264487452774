import { useQuery } from "@apollo/client";
import { GET_FILTERS } from "GraphQL/GetFilters";
import { createContext, useContext, useEffect, useState } from "react";
import useAuth from "../hooks/useAuth";
import { SearchContext } from "./SearchContext";

export const FiltersContext = createContext();

export const FiltersProvider = (props) => {
  const { query } = useContext(SearchContext);
  const auth = useAuth();

  const [result, setResult] = useState([]);
  const [adults, setAdults] = useState(parseInt(window.localStorage.getItem("adults")) || 2);
  //const [children, setChildren] = useState(0);
  const [countrySourceName, setCountrySourceName] = useState();
  const [citySourceName, setCitySourceName] = useState();
  const [regionSourceName, setRegionSourceName] = useState();
  const [offerType, setOfferType] = useState();
  const [departureCity, setDepartureCity] = useState();
  const [departureDateMin, setDepartureDateMin] = useState();
  const [departureDateMax, setDepartureDateMax] = useState();
  // const [priceMin, setPriceMin] = useState(200);
  // const [priceMax, setPriceMax] = useState(10000);
  const [periodMin, setPeriodMin] = useState(7);
  const [periodMax, setPeriodMax] = useState(21);
  const [maintenanceNames, setMaintenanceNames] = useState("");
  const [transportTypes, setTransportTypes] = useState("");
  const [propertyCategory, setPropertyCategory] = useState([]);
  const [semanticResponse, setSemanticResponse] = useState([]);

  const useRequestFilters = () => {
    return {
      query: query,
      adults: adults,
      // children: children,
      countrySourceName: countrySourceName || undefined,
      citySourceName: citySourceName || undefined,
      regionSourceName: regionSourceName || undefined,
      offerType: offerType || undefined,
      departureCity: departureCity || undefined,
      departureDate:
        departureDateMin || departureDateMax ? { min: departureDateMin || "", max: departureDateMax || "" } : undefined,
      // price: { min: priceMin, max: priceMax },
      period: { min: periodMin, max: periodMax },
      maintenanceName: maintenanceNames,
      transportType: transportTypes,
      propertyCategory: propertyCategory.length > 0 ? propertyCategory : undefined,
    };
  };

  const { error, data } = useQuery(GET_FILTERS, {
    skip: !auth.isAuthenticated,
    variables: {
      countryCode: window.localStorage.getItem("countryCode"),
      languageCode: window.localStorage.getItem("languageCode"),
      filters: useRequestFilters(),
    },
  });

  useEffect(() => {
    if (data) {
      setResult(data);
    }
  }, [data]);
  // if (loading) return <Loading />;
  if (error) return `Error! ${error.message}`;

  return (
    <FiltersContext.Provider
      value={{
        result,
        setResult,
        adults,
        setAdults,
        countrySourceName,
        setCountrySourceName,
        citySourceName,
        setCitySourceName,
        regionSourceName,
        setRegionSourceName,
        departureCity,
        setDepartureCity,
        setOfferType,
        departureDateMin,
        setDepartureDateMin,
        departureDateMax,
        setDepartureDateMax,
        useRequestFilters,
        // children,
        // setChildren,
        // priceMin,
        // setPriceMin,
        // priceMax,
        // setPriceMax,
        periodMin,
        setPeriodMin,
        periodMax,
        setPeriodMax,
        maintenanceNames,
        setMaintenanceNames,
        transportTypes,
        setTransportTypes,
        propertyCategory,
        setPropertyCategory,
        semanticResponse,
        setSemanticResponse,
      }}
    >
      {props.children}
    </FiltersContext.Provider>
  );
};

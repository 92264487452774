import { useState } from "react";

import { useTranslation } from "react-i18next";
import { generatePath } from "react-router";
import { Link, useNavigate } from "react-router-dom";

import _ from "lodash";

import { SINGLE_OFFER_PATH } from "config/routes";

import Rating from "@material-ui/lab/Rating";
import { Box, Tooltip, Typography } from "@mui/material";
import Button from "components/Base/Button";
import { Calendar22, DayOff, Food, HomeAddress, Info } from "components/Icons";
import SemanticList from "./SemanticList";

import styles from "./OfferItem.module.scss";

import defaultImage from "images/atif-zafrak.jpg";

import greenPricePrediction from "../../../images/icons/green-price-prediction-icon.svg";
import noDataIcon from "../../../images/icons/no-data-icon.svg";
import redPricePrediction from "../../../images/icons/red-price-prediction-icon.svg";
import { hasPermission } from "../../../utils/handlePermissions";
import Loading from "../../Base/Loading";
import Evaluation from "./Evaluation";

const OfferItem = ({
  offer: {
    id,
    name,
    score,
    property: { category, sourceCategory },
    cities,
    countries,
    regions,
    images,
    terms,
    semanticCategories,
    detectedSemanticCategories,
  },
  index,
  item_name,
  count,
  query,
  queryId,
  page,
  isRelatedOffer,
  prediction,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isImageError, setIsImageError] = useState(false);
  const [isImageLoading, setIsImageLoading] = useState(true);
  const [position, setPosition] = useState(index + 1);
  const [OnBoarding_step, setOnBoarding_step] = useState(0);

  const urlParams = new URLSearchParams(window.location.search);
  const semanticCategoriesArray = {};
  for (const [key, value] of urlParams.entries()) {
    if (key.startsWith("semanticCategory")) {
      semanticCategoriesArray[key.toLowerCase().slice(16)] = value;
    }
  }

  let image = defaultImage;
  if (Array.isArray(images)) {
    image = images[0]?.original || defaultImage;
  } else {
    image = images?.original[0] || defaultImage;
  }
  const firstTerm = terms[0];

  const departureDate = firstTerm?.departureDate;
  const departureCity = firstTerm?.departureCity;

  const maintenances = firstTerm.maintenances[0]?.name;
  const price = firstTerm?.personPrices?.[0]?.price?.toFixed(2) ?? null;
  const period = firstTerm.period;

  const rating = category ? category / 10 : 0;
  const city = cities?.sourceName || cities[0]?.sourceName;
  const country = countries?.sourceName || countries[0]?.sourceName;
  const region = regions?.sourceName || regions[0]?.sourceName;
  const currency = window.localStorage.getItem("currency");
  window.localStorage.setItem("page", page);

  const more_btn = () => {
    switch (window.localStorage.getItem("partnerSlug")) {
      case "demo_pl_classic_search":
        return t("id.button_more_id_classic");
      case "demo_en_classic_search":
        return t("id.button_more_id_classic");
      case "demo_pl_full_text_search":
        return t("id.button_more_id_fts");
      case "demo_en_full_text_search":
        return t("id.button_more_id_fts");
      default:
        return t("id.button_more_id");
    }
  };

  const handleMoreButtonClick = () => {
    window.localStorage.setItem("position", position);
    navigate({
      pathname: generatePath(SINGLE_OFFER_PATH, { id }),
      search: urlParams.get("childrenBirthDates")
        ? `termId=${firstTerm.id}&adults=${firstTerm.personsConfiguration.adults}&children=${
            firstTerm.personsConfiguration.children
          }&childrenBirthDates=${urlParams.get("childrenBirthDates")}`
        : `termId=${firstTerm.id}&adults=${firstTerm.personsConfiguration.adults}&children=${firstTerm.personsConfiguration.children}`,

    });
  };

  const semanticCategoriesParsed = _.transform(
    semanticCategories,
    (result, item, key) => {
      if (key !== "__typename" && item?.length > 0) {
        Object.values(item).map((model) => {
          let isDetected = false;
          Object.keys(detectedSemanticCategories).map((item) => {
            if (detectedSemanticCategories[item] && detectedSemanticCategories[item].includes(model)) {
              isDetected = true;
            }
          });
          return result.push({
            name: model,
            category_name: key,
            title: _.replace(model, /_/g, " "),
            query_exists: isDetected,
          });
        });
      }
    },
    []
  );

  const imageOnErrorHandler = (event) => {
    // event.currentTarget.style.display = "none";
    setIsImageError(true);
    setIsImageLoading(false);
  };

  const getNameFromData = (str) => {
    try {
      return Array.isArray(str) ? str.join(" / ") : JSON.parse(str).join(" / ");
    } catch (e) {
      return str;
    }
  };
  window.addEventListener("storage", () => {
    setOnBoarding_step(sessionStorage.getItem("OnBoarding_step") == 2 && index == 0 ? 1 : 0);
  });
  return (
    <Box className={styles.card} style={{ zIndex: 5000 * OnBoarding_step, important: true }}>
      <Box className={styles.imageWrapper}>
        {!isImageError && (
          <img src={image} alt={name} onError={imageOnErrorHandler} onLoad={() => setIsImageLoading(false)} />
        )}
        {isImageError && !isImageLoading && <span>{t("offers_page.offers_list.no_image")}</span>}
        {isImageLoading && <Loading />}
        {hasPermission("view_score") && score && (
          <Tooltip followCursor title={t("offers_page.offers_list.tooltip_offer_score")}>
            <div className={styles.score}>
              {t("offers_page.offers_list.score")}: {score} &nbsp; <Info className={styles.propertyIcon} />
            </div>
          </Tooltip>
        )}
        <Evaluation id={id} index={index} page={page} count={count} query={query} queryId={queryId} />
      </Box>
      <Box className={styles.containerWrapper}>
        <Box display="flex">
          <Box className={styles.detailsWrapper}>
            <Link
              to={{
                pathname: generatePath(SINGLE_OFFER_PATH, { id }),
                search: urlParams.get("childrenBirthDates")
                  ? `termId=${firstTerm.id}&adults=${firstTerm.personsConfiguration.adults}&children=${
                      firstTerm.personsConfiguration.children
                    }&childrenBirthDates=${urlParams.get("childrenBirthDates")}`
                  : `termId=${firstTerm.id}&adults=${firstTerm.personsConfiguration.adults}&children=${firstTerm.personsConfiguration.children}`,

              }}
              className={styles.title}
            >
              {name}
            </Link>
            <Box className={styles.locationWrapper}>
              {country && ` ${getNameFromData(country)}, `}
              {region && ` ${getNameFromData(region)}, `}
              {getNameFromData(city)}
            </Box>
            {rating > 0 && (
              <Box mt={0.5} mb={1} ml="-3px">
                <Rating value={rating} readOnly precision={0.5} />
              </Box>
            )}
          </Box>
          {hasPermission("prediction") && (
            <>
              {prediction.state === "up" && (
                <Tooltip
                  followCursor
                  title={t("offers_page.offers_list.price_prediction_negative_info", {
                    price: prediction.price.toFixed(2),
                    currency: currency,
                    date: prediction.date,
                  })}
                >
                  <img
                    src={redPricePrediction}
                    height="30"
                    alt="Price prediction Icon"
                    className={styles.pricePredictionIcon}
                  />
                </Tooltip>
              )}
              {prediction.state === "down" && (
                <Tooltip followCursor title={t("offers_page.offers_list.price_prediction_positive_info")}>
                  <img
                    src={greenPricePrediction}
                    height="30"
                    alt="Price prediction Icon"
                    className={styles.pricePredictionIcon}
                  />
                </Tooltip>
              )}
              {prediction.state === "same" && (
                <Tooltip followCursor title={t("offers_page.offers_list.price_prediction_same_price_info")}>
                  <img
                    src={noDataIcon}
                    height="30"
                    alt="Price prediction Icon"
                    className={styles.pricePredictionIcon}
                  />
                </Tooltip>
              )}
              {prediction.state === "error" && (
                <Tooltip followCursor title={t("offers_page.offers_list.price_prediction_no_data_info")}>
                  <img
                    src={noDataIcon}
                    height="30"
                    alt="Price prediction Icon"
                    className={styles.pricePredictionIcon}
                  />
                </Tooltip>
              )}
              {prediction.state === "loading" && (
                <Box sx={{ mt: "20px", mr: "10px" }}>
                  <Loading size={25} color="secondary" />
                </Box>
              )}
            </>
          )}
          <Box className={styles.priceWrapper}>
            {price && (
              <Box className={styles.price}>
                <Typography as="span" mr={1} fontSize="14px">
                  {t("main.from")}
                </Typography>
                <span>{price}</span>
                <Typography as="span" ml={1} fontSize="14px">
                  {currency}
                </Typography>
              </Box>
            )}
            <Button sx={{ my: 2, fontSize: "0.75rem" }} id={more_btn()} onClick={handleMoreButtonClick}>
              {t("offers_page.offers_list.view_more")}
            </Button>
          </Box>
        </Box>

        {window.localStorage.getItem("partnerSlug") !== "baboo_travel" &&
          window.localStorage.getItem("partnerSlug") !== "explore_co_uk" && (
            <Box className={styles.propertyItems}>
              <Box display="flex" flex="none" flexWrap="nowrap" alignItems="center" mb={1} mr={1}>
                <Calendar22 className={styles.propertyIcon} />
                <span>{departureDate}</span>
              </Box>
              {period && (
                <Box display="flex" alignItems="center" mr={1} mb={1}>
                  <DayOff className={styles.propertyIcon} />
                  <span>
                    {period} {t("offers_page.offers_list.days")}
                  </span>
                </Box>
              )}
              {departureCity && (
                <Box display="flex" alignItems="center" mr={1} mb={1}>
                  <HomeAddress className={styles.propertyIcon} />
                  <span>{departureCity}</span>
                </Box>
              )}
              {maintenances && (
                <Box display="flex" alignItems="center" mr={1} mb={1}>
                  <Food className={styles.propertyIcon} />
                  <span className={styles.propertyLabel}>{maintenances}</span>
                </Box>
              )}
            </Box>
          )}
        {!isRelatedOffer && (
          <Box className={styles.semanticFilters}>
            <SemanticList
              semanticCategories={semanticCategoriesParsed}
              chosenCategories={semanticCategoriesArray || []}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default OfferItem;

import React from "react";
import { createSvgIcon } from "@mui/material/utils";

export default createSvgIcon(
  <svg data-name="Layer 1" viewBox="0 0 40 40" id="tick">
    <path
      d="M15.48 28.62a1 1 0 0 1-.71-.29l-7.54-7.54a1 1 0 0 1 0-1.41 1 1 0 0 1 1.42 0l6.83 6.83L32.12 9.57a1 1 0 0 1 1.41 0 1 1 0 0 1 0 1.42L16.18 28.33a1 1 0 0 1-.7.29Z"
      fill="#000000"
      class="color000000 svgShape"
    ></path>
  </svg>
);

import React from "react";

import Header from "components/Header";
import Footer from "components/Footer";
import { Box, Container, Typography } from "@mui/material";

import _ from "lodash";
import { STORAGE_THEME_NAME } from "config/consts";
import { HOME_PAGE_THEMES } from "../../Homepage/theme";
import styles from "../errors.module.scss";
import err_page_img from "../../../images/error-page-image.png";
import { useTranslation } from "react-i18next";
import { Button } from "../../../components/Base";
import { useNavigate } from "react-router-dom";

const getTheme = () => {
  const themeIndex = sessionStorage.getItem(STORAGE_THEME_NAME);

  if (themeIndex) {
    return HOME_PAGE_THEMES[themeIndex];
  } else {
    const randomKey = _.random(0, HOME_PAGE_THEMES.length - 1);
    sessionStorage.setItem(STORAGE_THEME_NAME, randomKey);
    return HOME_PAGE_THEMES[randomKey];
  }
};

const Page401 = () => {
  const homePageTheme = getTheme();
  const { t } = useTranslation();

  const navigate = useNavigate();
  const goToHomepage = () => navigate({ pathname: "/" });

  window.onbeforeunload = () => {
    sessionStorage.removeItem(STORAGE_THEME_NAME);
  };

  return (
    <Box
      bgcolor={homePageTheme.background.bgcolor !== "#064357" ? homePageTheme.background.bgcolor : "#00B3F9"}
      className={styles.themeWrapper}
    >
      <Container maxWidth="lg">
        <Header />
        <Box className={styles.errorBox}>
          <Box className={styles.decorationImage}>
            <img src={err_page_img} alt="Error decoration image" />
          </Box>
          <Box className={styles.errorPageTextBox}>
            <Typography as="h1" className={styles.error}>
              401
            </Typography>
            <Typography as="h2" className={styles.errorPageTitle}>
              {t("errors.page401Title")}
            </Typography>
            <Typography as="h3" className={styles.errorPageSubtitle}>
              {t("errors.page401Subtitle")}
            </Typography>
            <Button onClick={goToHomepage} className={styles.homepageButton}>
              {t("main.go_back_to_search")}
            </Button>
          </Box>
        </Box>
      </Container>
      <Footer />
    </Box>
  );
};

export default Page401;

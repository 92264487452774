import { ApolloClient, createHttpLink, InMemoryCache, ApolloLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";

const partnerUrl = localStorage.getItem("partnerQapiUrl");
const httpLink = createHttpLink({
  uri: partnerUrl,
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token fromauthorization: token ? `Bearer local storage if it exists
  const token = localStorage.getItem("qapiToken"); // localStorage.getItem("token");
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const link = ApolloLink.from([authLink, httpLink]);

const client = new ApolloClient({
  link,
  cache: new InMemoryCache(),
});

class GraphQLApiService {
  query(query, variables) {
    return client.query({ query, variables });
  }
}
export const graphQLApiService = new GraphQLApiService();

import { Box } from "@mui/material";
import facebook from "images/icons/facebook-f.svg";
import linkedin from "images/icons/linkedin.svg";
import youtube from "images/icons/youtube.svg";
import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./Footer.module.scss";

const FooterSocial = () => {
  const { t } = useTranslation();

  return (
    <Box className={styles.footerSocial}>
      <h3>{t("shared.follow_us")}</h3>
      <a href="https://www.linkedin.com/company/qtravel-ai/" target="_blank" rel="noreferrer">
        <img src={linkedin} alt="Linkedin Icon"></img>
      </a>
      <a href="https://www.facebook.com/qtravelai" target="_blank" rel="noreferrer">
        <img src={facebook} alt="Facebook Icon"></img>
      </a>
      <a href="https://www.youtube.com/channel/UCG9AUGbn7EdpBod07VW9atw" target="_blank" rel="noreferrer">
        <img src={youtube} alt="Youtube Icon"></img>
      </a>
    </Box>
  );
};

export default FooterSocial;

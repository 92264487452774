import { Box, Typography } from "@mui/material";
import _ from "lodash";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import ItemList from "./ItemList";

import styles from "./Destination.module.scss";

const Destination = ({ data, onChange, selectedCountry = null, selectedRegion = null, selectedCity = null }) => {
  const { t } = useTranslation();
  const [isShowAll, setIsShowAll] = useState(false);
  let checkedCount = 0;

  const sortedData = _.orderBy(data, ({ countOffers }) => countOffers || 0, ["desc"]);

  return (
    <Box mb={3} mx={0} borderRadius={4}>
      <Typography as="h3" color="#000" fontFamily="Sharp Grotesk Medium" fontSize="16px" fontWeight="bold" mb={1}>
        {t("offers_page.filters.destination")}
      </Typography>

      <ul className={styles.countryList}>
        {sortedData.map(
          (
            {
              value: country,
              countOffers: countOffers,
              count: countryCountOffers,
              minPrice: minPrice,
              regionSourceName: regions,
            },
            index
          ) => {
            const countryKey = `dest-${_.kebabCase(country)}`;
            const countryChecked =
              selectedCountry === country ||
              (Array.isArray(selectedCountry) && selectedCountry.indexOf(country) !== -1);

            if (countryChecked && index > 10) {
              checkedCount += 1;
            }

            if (index <= 10 || countryChecked) {
              return (
                <ItemList
                  name={country}
                  countOffers={countOffers}
                  count={countryCountOffers}
                  minPrice={minPrice}
                  key={countryKey}
                  id={countryKey}
                  isChecked={countryChecked}
                  onChange={onChange("countrySourceName")}
                >
                  {countryChecked && regions.length > 0 && (
                    <ul className={styles.regionList}>
                      {regions.map(
                        ({
                          value: region,
                          countOffers: countOffers,
                          count: count,
                          minPrice: minPrice,
                          citySourceName: cities,
                        }) => {
                          const regionKey = `${countryKey}-${_.kebabCase(region)}`;
                          const regionChecked =
                            selectedRegion === region ||
                            (Array.isArray(selectedRegion) && selectedRegion.indexOf(region) !== -1);

                          return (
                            <ItemList
                              name={region}
                              key={regionKey}
                              id={regionKey}
                              countOffers={countOffers}
                              count={count}
                              minPrice={minPrice}
                              isChecked={regionChecked}
                              onChange={onChange("regionSourceName")}
                            >
                              {regionChecked && cities && cities.length > 0 && (
                                <ul className={styles.cityList}>
                                  {cities.map(
                                    ({ value: city, countOffers: countOffers, count: count, minPrice: minPrice }) => {
                                      const cityKey = `${regionKey}-${_.kebabCase(city)}`;
                                      const cityChecked =
                                        selectedCity === city ||
                                        (Array.isArray(selectedCity) && selectedCity.indexOf(city) !== -1);

                                      return (
                                        <ItemList
                                          name={city}
                                          isChecked={cityChecked}
                                          key={cityKey}
                                          id={cityKey}
                                          countOffers={countOffers}
                                          count={count}
                                          minPrice={minPrice}
                                          onChange={onChange("citySourceName")}
                                        />
                                      );
                                    }
                                  )}
                                </ul>
                              )}
                            </ItemList>
                          );
                        }
                      )}
                    </ul>
                  )}
                </ItemList>
              );
            } else if (isShowAll) {
              return (
                <ItemList
                  name={country}
                  countOffers={countOffers}
                  count={countryCountOffers}
                  minPrice={minPrice}
                  key={countryKey}
                  id={countryKey}
                  isChecked={countryChecked}
                  onChange={onChange("countrySourceName")}
                >
                  {countryChecked && regions.length > 0 && (
                    <ul className={styles.regionList}>
                      {regions.map(
                        ({
                          value: region,
                          citySourceName: cities,
                          countOffers: countOffers,
                          count: count,
                          minPrice: minPrice,
                        }) => {
                          const regionKey = `${countryKey}-${_.kebabCase(region)}`;
                          const regionChecked = selectedRegion === region;

                          return (
                            <ItemList
                              name={region}
                              key={regionKey}
                              id={regionKey}
                              countOffers={countOffers}
                              count={count}
                              minPrice={minPrice}
                              isChecked={regionChecked}
                              onChange={onChange("regionSourceName")}
                            >
                              {regionChecked && cities.length > 0 && (
                                <ul className={styles.cityList}>
                                  {cities.map(({ value: city }) => {
                                    const cityKey = `${regionKey}-${_.kebabCase(city)}`;
                                    const cityChecked = selectedCity === city;

                                    return (
                                      <ItemList
                                        name={city}
                                        isChecked={cityChecked}
                                        key={cityKey}
                                        id={cityKey}
                                        countOffers={countOffers}
                                        count={count}
                                        minPrice={minPrice}
                                        onChange={onChange("citySourceName")}
                                      />
                                    );
                                  })}
                                </ul>
                              )}
                            </ItemList>
                          );
                        }
                      )}
                    </ul>
                  )}
                </ItemList>
              );
            }
          }
        )}
      </ul>
      {data.length > 11 + checkedCount && (
        <div>
          {isShowAll && (
            <p onClick={() => setIsShowAll(!isShowAll)} className={styles.showMoreLessToggle}>
              {t("offers_page.filters.show_less")}
            </p>
          )}
          {!isShowAll && (
            <p onClick={() => setIsShowAll(!isShowAll)} className={styles.showMoreLessToggle}>
              {t("offers_page.filters.show_all")} {data.length}
            </p>
          )}
        </div>
      )}
    </Box>
  );
};

export default Destination;

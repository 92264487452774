import Rating from "@material-ui/lab/Rating";
import { Box, Typography } from "@mui/material";
import { SINGLE_OFFER_PATH } from "config/routes";
import defaultImage from "images/atif-zafrak.jpg";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { generatePath } from "react-router";
import { Link } from "react-router-dom";
import Loading from "../../Base/Loading";
import styles from "./RealatedOfferItem.module.scss";

const RelatedOfferItem = ({
  offer: {
    id,
    name,
    property: { category, sourceCategory },
    cities,
    countries,
    regions,
    images,
    terms,
  },
}) => {
  const { t } = useTranslation();
  const [isImageError, setIsImageError] = useState(false);
  const [isImageLoading, setIsImageLoading] = useState(true);

  let image = defaultImage;
  if (Array.isArray(images)) {
    image = images[0]?.original || defaultImage;
  } else {
    image = images?.original[0] || defaultImage;
  }

  const price = terms?.price ? terms.price.min.toFixed(2) : null;
  const rating = category ? category / 10 : 0;
  const city = cities && cities.sourceName && cities.sourceName[0];
  const country = countries && countries.sourceName && countries.sourceName[0];
  const region = regions && regions.sourceName && regions.sourceName[0];
  const currency = window.localStorage.getItem("currency");

  const imageOnErrorHandler = () => {
    setIsImageError(true);
    setIsImageLoading(false);
  };

  const getNameFromData = (str) => {
    try {
      return Array.isArray(str) ? str.join(" / ") : JSON.parse(str).join(" / ");
    } catch (e) {
      return str;
    }
  };

  return (
    <Link to={{ pathname: generatePath(SINGLE_OFFER_PATH, { id }) }} className={styles.wrapper}>
      <Box className={styles.cardRealtedOffer}>
        <Box className={styles.imageWrapper}>
          {!isImageError && (
            <img src={image} alt={name} onError={imageOnErrorHandler} onLoad={() => setIsImageLoading(false)} />
          )}
          {isImageError && !isImageLoading && <span>{t("offers_page.offers_list.no_image")}</span>}
          {isImageLoading && <Loading />}
        </Box>

        <Box className={styles.detailsWrapper}>
          <Box className={styles.titleWrapper}>{name}</Box>
          <Box className={styles.locationWrapper}>
            {country && ` ${getNameFromData(country)}, `}
            {region && ` ${getNameFromData(region)}, `}
            {getNameFromData(city)}
          </Box>
          {rating > 0 && (
            <Box mt={0.5} mb={1} ml="-3px">
              <Rating value={rating} readOnly precision={0.5} />
            </Box>
          )}
          <Box className={styles.priceWrapper}>
            {price && (
              <Box className={styles.price}>
                <Typography as="span" mr={1} fontSize="14px">
                  {t("main.from")}
                </Typography>
                <span>{price}</span>
                <Typography as="span" ml={1} fontSize="14px">
                  {currency}
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Link>
  );
};

export default RelatedOfferItem;

import { useQuery } from "@apollo/client";
import { GET_OFFER } from "GraphQL/GetOffer";
import { Loader } from "components/Base";
import { createContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { GET_RAINBOW_OFFER } from "../GraphQL/GetRainbowOffer";
import { GET_SIMILAR_OFFERS } from "../GraphQL/GetSimilarOffers";
import useAuth from "../hooks/useAuth";

export const SingleOfferContext = createContext();

export const SingleOfferProvider = (props) => {
  const isMounted = useRef(true);

  const urlString = window.location.search;
  const params = new URLSearchParams(urlString);
  const ArrayFilters = { adults: parseInt(params.get("adults")), children: parseInt(params.get("children")) };

  const auth = useAuth();

  const { id } = useParams();
  const [offer, setOffer] = useState([]);
  const [similarOffers, setSimilarOffers] = useState([]);

  const { error, loading, data, refetch } = useQuery(
    window.localStorage.getItem("partnerSlug") === "rainbow" ? GET_RAINBOW_OFFER : GET_OFFER,
    {
      skip: !auth.isAuthenticated,
      variables: {
        countryCode: window.localStorage.getItem("countryCode"),
        languageCode: window.localStorage.getItem("languageCode"),
        order: ["PRICE_ASC"],
        id: id,
        filters: ArrayFilters,
      },
      nextFetchPolicy: "cache-only",
    }
  );

  const { loading: similarOffersLoading, data: similarOffersData } = useQuery(GET_SIMILAR_OFFERS, {
    skip: !auth.isAuthenticated,
    variables: {
      countryCode: window.localStorage.getItem("countryCode"),
      languageCode: window.localStorage.getItem("languageCode"),
      id: id,
    },
  });

  useEffect(() => {
    if (isMounted.current) {
      isMounted.current = false;
    } else {
      refetch();
    }
  }, [id]);

  useEffect(() => {
    if (data) {
      setOffer(data);
    }
    if (similarOffersData) {
      setSimilarOffers(similarOffersData);
    }
  }, [data, similarOffersData]);

  if (loading || similarOffersLoading) return <Loader />;
  if (error) return `Error! ${error.message}`;

  const value = {
    offer,
    setOffer,
    similarOffers,
    setSimilarOffers,
  };
  return <SingleOfferContext.Provider value={value}>{props.children}</SingleOfferContext.Provider>;
};

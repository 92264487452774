import { Box, Button, Typography } from "@mui/material";
import styles from "./ContactBox.module.scss";
import { QTRAVELAI } from "config/routes";
import { useTranslation } from "react-i18next";

const Contact = ({ contextText, contextTextButton, boxColor, buttonColor }) => {
  const { t } = useTranslation();

  return (
    <Box className={styles.container} style={{ backgroundColor: `${boxColor}` }}>
      <Typography className={styles.contactText}> {contextText}</Typography>
      <Button
        onClick={() => window.open(`${QTRAVELAI + t("url.contact")}` + "/#cf")}
        style={{ backgroundColor: `${buttonColor}` }}
      >
        {contextTextButton}
      </Button>
    </Box>
  );
};

export default Contact;

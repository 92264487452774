import { gql } from "@apollo/client";

export const GET_TERM = gql`
  query GetTerm($countryCode: Country!, $languageCode: Language!, $id: ID!, $filters: Filters) {
    term(countryCode: $countryCode, languageCode: $languageCode, id: $id, filters: $filters) {
      offerDetails {
        terms {
          id
          departureCity
          departureDate
          period
          persons
          personPrices {
            type
            age
            ageRange
            price
          }
          personsConfiguration {
            adults
            children
          }
          basePrice
          maintenances {
            name
            sourceName
          }
        }
      }
    }
  }
`;

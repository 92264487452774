import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../../Base";
import { Box, Tooltip } from "@mui/material";
import styles from "./PriceInput.module.scss";
import { useLocation } from "react-router-dom";
import icon from "../../../images/icons/search-solid.svg";

const PriceInput = ({ onChange, minPrice, maxPrice }) => {
  const { t } = useTranslation();
  const urlParams = new URLSearchParams(window.location.search);
  const minParam = urlParams.get("price")?.split("_")[1].split("-")[1] || "";
  const maxParam = urlParams.get("price")?.split("_")[0].split("-")[1] || "";
  const [min, setMin] = useState(minParam);
  const [max, setMax] = useState(maxParam);
  const [error, setError] = useState({ status: false, msg: "" });

  useEffect(async () => {
    const minParam2 = urlParams.get("price")?.split("_")[1].split("-")[1] || "";
    const maxParam2 = urlParams.get("price")?.split("_")[0].split("-")[1] || "";
    await setMin(minParam2);
    await setMax(maxParam2);
  }, [window.location.search]);

  const onChangeBefore = (event) => {
    event.preventDefault();
    if (!isNaN(min) && min != "" && (max == "" || max == undefined)) {
      onChange({ min: parseInt(min), max: maxPrice });
      setError({ status: false, msg: "" });
    } else if (!isNaN(max) && max != "" && (min == "" || min == undefined)) {
      onChange({ max: parseInt(max), min: minPrice });
      setError({ status: false, msg: "" });
    } else if (!isNaN(min) && !isNaN(max) && parseInt(min) < parseInt(max)) {
      setError({ status: false, msg: "" });
      onChange({ min: parseInt(min), max: parseInt(max) });
    } else if (min != undefined && max != undefined) {
      if (min == "" && max == "") {
        onChange();
      } else if (parseInt(min) >= parseInt(max)) {
        setError({ status: true, msg: t("offers_page.filters.priceInputErrorMin") });
      } else {
        setError({ status: true, msg: "Not a number" });
      }
    } else {
      onChange();
    }
  };

  return (
    <Tooltip followCursor title={t("offers_page.filters.priceToolTip")}>
      <Box className={styles.priceInput_Box}>
        <form className={styles.priceInput_container} onSubmit={onChangeBefore}>
          <label className={styles.priceInput_Label}>{t("offers_page.filters.from")}</label>
          <input value={min} onChange={(e) => setMin(e.target.value.replace(/\D/g, ""))} />
          <label className={styles.priceInput_Label}>{t("offers_page.filters.to")}</label>
          <input value={max} onChange={(e) => setMax(e.target.value.replace(/\D/g, ""))} />
          <Button className={styles.icon_button} type="submit">
            <img src={icon} alt="Search" className={styles.searchIcon}></img>
          </Button>
        </form>
        {error.status && <span className={styles.priceInput_Error}>{error.msg}</span>}
      </Box>
    </Tooltip>
  );
};

export default PriceInput;

import { useWindowDimensions } from "hooks/useWindowDimensions";
import { useEffect, useState } from "react";
import TagManager from "react-gtm-module";
import { useTranslation } from "react-i18next";

import _ from "lodash";

import icon from "images/icons/search-solid.svg";

import { Autocomplete } from "qtravelpl_autocomplete";
import { hasPermission } from "../../utils/handlePermissions";
import { Button } from "../Base";
import styles from "./SearchInput.module.scss";

import { SCHEMA_SEARCH, SCHEMA_SEARCH_BY_ENTER } from "../../config/snowplow";
import useSnowplowAnalytics from "../../hooks/useSnowplowAnalytics";

const SearchInput = ({ searchQuery, setSearchQuery }) => {
  const { t } = useTranslation();

  // TODO: Move to AppContext
  const { width } = useWindowDimensions();

  let [query, setQuery] = useState(searchQuery);

  const analytics = useSnowplowAnalytics();

  const triggerSearch = (withQuery = "") => {
    TagManager.dataLayer({
      dataLayer: {
        event: "search",
        search_term: withQuery ? withQuery : query,
        language: window.localStorage.getItem("i18nextLng"),
        userProject: window.localStorage.getItem("partnerSlug"),
        userId: window.localStorage.getItem("email").replace("@qtravel.ai", ""),
      },
    });
    analytics.track("search", {
      contexts: [
        {
          schema: SCHEMA_SEARCH,
          data: {
            event: "search",
            search_term: withQuery ? withQuery : query,
            language: window.localStorage.getItem("i18nextLng"),
            userProject: window.localStorage.getItem("partnerSlug"),
            userId: window.localStorage.getItem("email").replace("@qtravel.ai", ""),
          },
        },
      ],
    });
    setSearchQuery(withQuery ? withQuery : query);
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      TagManager.dataLayer({
        dataLayer: {
          event: "searchByEnter",
          search_term: query,
          language: window.localStorage.getItem("i18nextLng"),
          userProject: window.localStorage.getItem("partnerSlug"),
          userId: window.localStorage.getItem("email").replace("@qtravel.ai", ""),
        },
      });
      analytics.track("searchByEnter", {
        contexts: [
          {
            schema: SCHEMA_SEARCH_BY_ENTER,
            data: {
              event: "searchByEnter",
              search_term: query,
              language: window.localStorage.getItem("i18nextLng"),
              userProject: window.localStorage.getItem("partnerSlug"),
              userId: window.localStorage.getItem("email").replace("@qtravel.ai", ""),
            },
          },
        ],
      });
      setSearchQuery(query);
    }
  };

  useEffect(() => {
    setQuery(_.trim(searchQuery));
  }, [searchQuery]);

  const textareaPlaceholder = t(width < 500 ? "main.form_placeholder_mobile" : "main.form_placeholder");
  const search_btn_id = () => {
    switch (window.localStorage.getItem("partnerSlug")) {
      case "demo_pl_classic_search":
        return t("id.button_search_id_classic");
      case "demo_en_classic_search":
        return t("id.button_search_id_classic");
      case "demo_pl_full_text_search":
        return t("id.button_search_id_fts");
      case "demo_en_full_text_search":
        return t("id.button_search_id_fts");
      default:
        return t("id.button_search_id");
    }
  };

  return (
    <>
      <div className={styles.searchWrapper}>
        <textarea
          name="searchTerm"
          maxLength={300}
          value={query || ""}
          id="offers-search"
          onChange={(e) => {
            setQuery(_.trim(e.target.value, "\n"));

            const autocompleteDiv = document.querySelector(".autocompleteContainer");
            if (autocompleteDiv) autocompleteDiv.style.display = "block";
          }}
          onKeyDown={handleKeyDown}
          className={styles.inputSearch}
          placeholder={textareaPlaceholder}
          onKeyPress={handleKeyPress}
          required
        />
        <Button id={search_btn_id()} onClick={() => triggerSearch()} className={styles.searchButton}>
          <img src={icon} alt="Search" className={styles.searchIcon}></img>
        </Button>
      </div>
      {hasPermission("autocomplete") && (
        <Autocomplete
          uri={localStorage.getItem("partnerQapiUrl")}
          query={query}
          hideOnClick={true}
          itemClicked={(item) => {
            setQuery(item.value.join(" "));
            triggerSearch(item.value.join(" "));
            const autocompleteDiv = document.querySelector(".autocompleteContainer");
            if (autocompleteDiv) autocompleteDiv.style.display = "none";
          }}
          countryCode={window.localStorage.getItem("countryCode")}
          languageCode={window.localStorage.getItem("languageCode")}
          qapiToken={window.localStorage.getItem("qapiToken")}
          token={window.localStorage.getItem("token")}
        />
      )}
    </>
  );
};

export default SearchInput;

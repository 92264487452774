import { Box, Checkbox } from "@mui/material";
import { useTranslation } from "react-i18next";
import styles from "./PriceRangeRadio.module.scss";
import { useEffect, useState } from "react";

const PriceRangeRadio = ({ data, onChange, minPriceValue }) => {
  const { t } = useTranslation();
  const urlParams = new URLSearchParams(window.location.search);
  const minParam = urlParams.get("price")?.split("_")[1].split("-")[1] || null;
  const maxParam = urlParams.get("price")?.split("_")[0].split("-")[1] || null;
  const currency = window.localStorage.getItem("currency");
  const [checkedItems, setCheckedItems] = useState([]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const minParam = urlParams.get("price")?.split("_")[1].split("-")[1] || null;
    const maxParam = urlParams.get("price")?.split("_")[0].split("-")[1] || null;

    const newCheckedItems = data.ranges.map((item, index) => {
      return item.count > 0 && maxParam > item.min && minParam < item.max;
    });
    setCheckedItems(newCheckedItems);
  }, [window.location.search]);

  const handleRadioChange = (event) => {
    const [maxEvent, minEvent, index] = JSON.parse("[" + event.target.value + "]");
    if (event.target.checked) {
      const max = maxEvent > maxParam ? maxEvent : maxParam;
      const min = minEvent > minParam && urlParams.get("price") ? minParam : minEvent ? minEvent : 0;
      onChange({ max, min });
    } else {
      if (minEvent > parseInt(minParam)) {
        const max = minEvent;
        const min = parseInt(minParam);
        min === max || minEvent === minPriceValue ? onChange(undefined) : onChange({ max, min });
      } else {
        if (minEvent === parseInt(minParam) && maxEvent === parseInt(maxParam)) {
          onChange(undefined);
        } else {
          const max = parseInt(maxParam);
          const min = maxEvent;
          if (min === max) {
            onChange(undefined);
          } else {
            onChange({ max, min });
          }
        }
      }
    }
  };

  return (
    <Box sx={{ mb: 5, pl: 3 }}>
      <ul className={styles.list}>
        {data?.ranges?.map((item, index) => {
          const key = `priceCheckbox-${index}`;
          const isChecked = item.count > 0 && maxParam > item.min && minParam < item.max;
          return (
            <li className={styles.item} key={key}>
              <Checkbox
                className={styles.checkbox}
                size="normal"
                checked={checkedItems[index] == undefined ? isChecked : checkedItems[index]}
                value={`${item.range.max || item.max}, ${item.range.min || item.min}, ${index}`}
                onChange={handleRadioChange}
                id={key}
                disabled={item.count === 0}
              />
              <label className={item.count !== 0 ? "" : styles.isDisable}>
                {index === 0 || index === data.ranges.length - 1 ? (
                  index === 0 ? (
                    <span> {`${t("offers_page.filters.to")} ${item.max ? item.max : item.range.max}${currency}`} </span>
                  ) : (
                    <span>
                      {" "}
                      {`${t("offers_page.filters.from")} ${item.min ? item.min : item.range.min}${currency}`}{" "}
                    </span>
                  )
                ) : (
                  <span>{`${item.range.min}${currency} - ${item.range.max}${currency}`}</span>
                )}
              </label>
            </li>
          );
        })}
      </ul>
    </Box>
  );
};

export default PriceRangeRadio;

import CheckboxList from "../../../SidebarFilters/CheckboxList";
import { format } from "date-fns";

const MonthList = ({ data, selected, onChange }) => {
  const formatDate = (date) => format(new Date(date), "yyyy-MM-dd");
  const getLastDayOfMonth = (year, month) => {
    return new Date(year, month, 0);
  };

  const onChangeMonth = (event) => {
    const { value, checked } = event.target || ["", false];
    const [year, month, day] = value.split("-");
    const newDates = checked
      ? [formatDate(new Date(year, month - 1, day)), formatDate(getLastDayOfMonth(year, month))]
      : ["", ""];
    onChange(newDates);
  };

  return (
    <CheckboxList
      data={data.filter((el) => el.count > 0)}
      selected={selected}
      onChange={onChangeMonth}
      variantColor={true}
    />
  );
};

export default MonthList;

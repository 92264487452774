import Header from "components/Header";
import SearchSemanticModel from "components/SearchSemanticModel";

import { Box, Container } from "@mui/material";
import SearchInput from "components/SearchInput";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { StringParam, useQueryParam } from "use-query-params";
import { Button } from "../../../components/Base";
import ClassicSearchForm from "../../../components/ClassicSearch";
import { hasPermission } from "../../../utils/handlePermissions";

const SearchHeader = ({ query, setSearchQuery, semanticResponse, isLoading }) => {
  const { t } = useTranslation();
  const [exampleQuery, setQuery] = useQueryParam("query", StringParam);
  const setSearchText = (e) => setQuery(_.trim(e.target.name, "\n"));

  const examplesTmp = localStorage.getItem("query_examples");
  let examples = [];
  try {
    examples = examplesTmp && examplesTmp !== "undefined" ? JSON.parse(examplesTmp) : [];
  } catch (e) {
    examples = [];
  }

  return (
    <>
      <Box bgcolor="#00B3F9" color="#FFF" pb={7}>
        <Container maxWidth="1100px">
          <Header />
          {!hasPermission("can_view_classic_form") && (
            <Box maxWidth="1100px" mx="auto">
              {window.localStorage.getItem("partnerSlug") !== "rainbow" && (
                <Box alignSelf="flex-start" id="offers-examples">
                  {!!examples &&
                    !!examples.length &&
                    examples.map((example, index) => (
                      <Button
                        name={decodeURIComponent(escape(example.example))}
                        size="large"
                        variant="text"
                        sx={{ color: "#fff" }}
                        onClick={setSearchText}
                        key={`example-${index + 1}`}
                        id={`offers-example-${index + 1}`}
                      >
                        {t("shared.example")} {index + 1}
                      </Button>
                    ))}
                </Box>
              )}
              <SearchInput searchQuery={query} setSearchQuery={setSearchQuery} maxWidth="1100px" />
              {semanticResponse && <SearchSemanticModel semanticResponse={semanticResponse} />}
            </Box>
          )}
          {hasPermission("can_view_classic_form") && <ClassicSearchForm />}
        </Container>
      </Box>
    </>
  );
};

export default SearchHeader;

import React from "react";
import { createSvgIcon } from "@mui/material/utils";

const Lightbulb = createSvgIcon(
  <svg viewBox="0 0 512 512" id="lightbulb">
    <path
      d="M256 32c-70.7 0-128 55.4-128 123.8 0 4.9.3 9.7.9 14.5.6 5.4 1.6 10.6 3 15.7.1.5.3 1.1.4 1.6 16.6 62.8 45.3 71.5 58.9 167.6 0 .2.1.4.1.5 1.5 9.2 9.8 12.3 19.8 12.3H301c10 0 18.2-3.1 19.7-12.3 0-.2.1-.4.1-.5 13.6-96.1 42.3-104.7 58.9-167.6.2-.5.3-1 .4-1.6 1.3-5.1 2.3-10.4 3-15.7.6-4.7.9-9.6.9-14.5C384 87.4 326.7 32 256 32zM317.8 396.5c.1-.2.3-.4.4-.6 1.1-1.7 1.7-3.6 1.7-5.7 0-3.5 1.6-6.2-6.5-6.2H198.6c-8.1 0-6.5 2.1-6.5 6.2 0 2.1.6 4 1.7 5.7.1.2.3.4.5.6 0 .1.1.1.1.2 1.7 2.6 2.7 4.4 2.7 7.6 0 3.1-.9 4.9-2.6 7.5-.3.4-.5.7-.7 1-1 1.7-1.6 3.6-1.6 5.6 0 2.1.6 4 1.7 5.8.1.2.3.4.4.6 1.8 2.7 2.8 4.5 2.8 7.8 0 3.1-.9 4.9-2.6 7.4-.2.4-.5.7-.8 1.1-1 1.7-1.6 3.6-1.6 5.6 0 5.4 4.3 10.1 10.2 11.6.3.1.6.1.9.2 6 1.4 12.2 1.6 18.5 2.5.7.1 1.4.2 2.2.3 5.6 1 10.3 3.9 13.4 7.7 3.8 5.3 10.8 11 18.8 11 7.6 0 14.3-5.4 18.2-10.4 3-4.2 8-7.3 13.9-8.4.7-.1 1.4-.3 2.2-.3 6.3-.9 12.5-1.1 18.5-2.5.3-.1.6-.1.9-.2 5.9-1.6 10.2-6.2 10.2-11.6 0-2-.6-3.9-1.6-5.6-.3-.4-.5-.7-.8-1.1-1.6-2.6-2.6-4.3-2.6-7.4 0-3.2 1-5.1 2.8-7.8.1-.2.3-.4.4-.6 1.1-1.7 1.7-3.7 1.7-5.8 0-2-.6-3.9-1.6-5.6-.3-.3-.5-.7-.7-1-1.6-2.6-2.6-4.3-2.6-7.5s1-5 2.7-7.6c0 .1 0 0 .1-.1z"
      fill="#000000"
      class="color000000 svgShape"
    ></path>
  </svg>
);

export default Lightbulb;

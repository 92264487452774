import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../../../components/Base";
import styles from "./Cookies.module.scss";

const CookiesComponent = () => {
  const [showCookies, setShowCookies] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    const cookieValue = Cookies.get("cookies");

    if (!cookieValue) {
      Cookies.set("cookies", "true", { expires: 7 });
    } else {
      setShowCookies(false);
    }
  }, []);

  const hideCookies = () => {
    Cookies.set("cookies", "true", { expires: 7 });
    setShowCookies(false);
  };

  return (
    <>
      {showCookies && (
        <div id="cookiesInfo" className={styles.cookies_info}>
          <span>
            {t("cookies")}
            <a target="_blank" rel="noopener noreferrer">
              {t("cookies-pp")}
            </a>
          </span>
          <Button
            name={t("cookies-close")}
            size="large"
            onClick={hideCookies}
            sx={{
              marginTop: "16px",
              padding: "24px",
              minWidth: "200px",
              borderRadius: "39px",
              justifyContent: "center",
            }}
          >
            {t("cookies-close")}
          </Button>
        </div>
      )}
    </>
  );
};

export default CookiesComponent;

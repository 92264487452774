import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  JsonParam,
  NumberParam,
  NumericObjectParam,
  ObjectParam,
  StringParam,
  useQueryParams,
  withDefault,
} from "use-query-params";
import { hasPermission } from "../../utils/handlePermissions";

import useGetOffers from "hooks/useGetOffers";
import MetaTags from "react-meta-tags";

import _ from "lodash";

import Loading from "components/Base/Loading";
import Footer from "components/Footer";
import SearchHeader from "./SearchHeader";

import OffersList from "components/OffersList";
import SidebarFilters from "components/SidebarFilters";

import { Box, Container, Stack, Typography } from "@mui/material";

import { Grid } from "@material-ui/core";
import Error from "../../components/Base/Error";
import Sorting from "../../components/Sorting";
import { QTRAVELAI_CONTACT } from "../../config/routes";
import Page500 from "../Errors/page500";
import styles from "./Offers.module.scss";
import PriceSlider from "./PriceSlider";
import Onboarding from "components/Base/Onboarding/Onboarding";

const Offers = () => {
  const { t } = useTranslation();
  const [OnBoarding_step, setOnBoarding_step] = useState(sessionStorage.getItem("OnBoarding_step"));
  const [searchFilters, setSearchFilters] = useQueryParams({
    query: StringParam,
    adults: withDefault(NumberParam, parseInt(window.localStorage.getItem("adults")) || 2),
    children: withDefault(NumberParam, 0),
    childrenBirthDates: JsonParam,
    countrySourceName: JsonParam,
    citySourceName: JsonParam,
    regionSourceName: JsonParam,
    offerType: JsonParam,
    departureCity: JsonParam,
    departureDate: ObjectParam,
    periodRange: withDefault(NumericObjectParam, { min: 3, max: 21 }),
    period: JsonParam,
    maintenanceName: JsonParam,
    transportType: JsonParam,
    propertySourceCategory: JsonParam,
    propertyCategory: JsonParam,
    price: NumericObjectParam,
    additionalRegionData: JsonParam,
    semanticCategoryActivity: JsonParam,
    semanticCategoryCuisine: JsonParam,
    semanticCategoryHotelAmenities: JsonParam,
    semanticCategoryHotelAttractions: JsonParam,
    semanticCategoryLocalization: JsonParam,
    semanticCategoryNature: JsonParam,
    semanticCategoryOther: JsonParam,
    semanticCategoryRoomAmenities: JsonParam,
    semanticCategorySkiing: JsonParam,
    semanticCategoryTripAttractions: JsonParam,
  });
  const getSearchFilters = () => {
    const result = JSON.parse(JSON.stringify(searchFilters));
    if (result.regionSourceName && result.regionSourceName.length > 0 && result.countrySourceName.length > 1) {
      result.countrySourceName.map((countryName) => {
        if (result.additionalRegionData[countryName] && result.additionalRegionData[countryName].length > 0) {
          if (
            result.additionalRegionData[countryName].filter((countryRegion) =>
              result.regionSourceName.find((region) => countryRegion === region)
            ).length === 0
          ) {
            result.regionSourceName = [...result.regionSourceName, ...result.additionalRegionData[countryName]];
          }
        }
      });
    }
    delete result.additionalRegionData;
    return result;
  };

  const getQueryId = () => {
    return `${new Date().getTime().toString(36)}-${Math.round(Math.random() * 100000)}`;
  };

  let [semanticResponse, setSemanticResponse] = useState([]);
  const [randomSidebarKey, setRandomSidebarKey] = useState(new Date().getTime());
  const [minPrices, setMinPrices] = useState({});

  const [queryId, setQueryId] = useState(getQueryId());

  const [sort, setSort] = useQueryParams({ sort: withDefault(StringParam, "SCORE_DESC") });
  const [pagination, setPagination] = useQueryParams({ page: withDefault(StringParam, "1") });
  const { loading, data, error } = useGetOffers(getSearchFilters(), sort.sort, pagination.page);

  const setSearchQuery = (value) => {
    setSearchFilters({ query: value }, "replace");
    setQueryId(getQueryId());
  };

  const handleResetFilters = () => {
    setSearchFilters({}, "push");
    setRandomSidebarKey(new Date().getTime());
  };

  const handlePageChange = (event, page) => {
    setPagination({ page: page });
  };
  const [showOnboarding, setShowOnboarding] = useState(JSON.parse(localStorage.getItem("onboarding")));

  window.addEventListener("storage", () => {
    setShowOnboarding(JSON.parse(localStorage.getItem("onboarding")));
  });

  const handleClose = () => {
    const OnBoarding_step = sessionStorage.getItem("OnBoarding_step");
    if (OnBoarding_step == 6) {
      sessionStorage.setItem("OnBoarding_step", 0);
    }
    localStorage.setItem("onboarding", false);
    setShowOnboarding(false);
  };
  const getCurrentMinPrices = () => {
    let minPricesTmp = {};
    data?.search?.facets?.departureDate?.dailyPrices?.forEach((item) => {
      minPricesTmp[item.value] = item.minPrice;
    });
    setMinPrices(minPricesTmp);
  };

  useEffect(() => {
    if (JSON.parse(window.localStorage.getItem("onboarding")) == null) {
      window.localStorage.setItem("onboarding", true);
      setShowOnboarding(true);
    }
    let response;

    if (data?.search?.semanticResponse?.body) {
      const results = data.search.semanticResponse.body;
      response = _.flatten(_.map(results, (item) => Object.keys(item.aspects)));
    } else {
      response = [];
    }

    if (data?.search?.semanticResponse?.people?.children?.min && data?.search?.semanticResponse?.people?.adults?.min) {
      setSearchFilters({ adults: data.search.semanticResponse.people.adults.min });
      setSearchFilters({ children: data.search.semanticResponse.people.children.min });
    }

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    setSemanticResponse(response);
    getCurrentMinPrices();
  }, [data]);

  // Check error only (without data) is not enough. When query is changed to previous one (back button in browser)
  // `data` is updated, but `error` is not cleaned. Not sure if bug or design in Apollo client ;)
  if (error && !data) {
    return (
      <>
        {error.networkError?.statusCode === 500 && <Page500 />}
        {error.networkError?.statusCode !== 500 && (
          <>
            <SearchHeader
              query={searchFilters.query}
              setSearchQuery={setSearchQuery}
              isLoading={loading}
              semanticResponse={data?.search?.semanticResponse}
            />
            <Container maxWidth="lg">
              <Box mt={10} mb={10}>
                <Error error={error} />
              </Box>
            </Container>
            <Box>
              <Footer />
            </Box>
          </>
        )}
      </>
    );
  }
  const searchResultsText = () => {
    let totalTermsCount = 0;

    if (data?.search?.offers) {
      totalTermsCount = data.search.totalTripCount || 0;
    }
    switch (data && data.search?.count) {
      case 0:
        return (
          <>
            <Typography variant="h6">{t("offers_page.offers_list.we_found_zero_results_title")}</Typography>
            <Typography variant="subtitle1">{t("offers_page.offers_list.we_found_zero_results_subtitle")}</Typography>
            <Typography variant="subtitle1" mt={5}>
              <a href={QTRAVELAI_CONTACT} className={styles.contactLink}>
                {t("shared.contact_us")}
              </a>{" "}
              &nbsp;
              <span>{t("offers_page.offers_list.we_found_zero_results_cta_text")}</span>
            </Typography>
          </>
        );
      default:
        return (
          <Typography variant="h5" fontWeight="bold">
            {t("offers_page.offers_list.search_results")} - {(data && data.search?.count) || 0}{" "}
            {t("offers_page.offers_list.offers")} ({totalTermsCount || 0} {t("offers_page.offers_list.terms")})
          </Typography>
        );
    }
  };

  window.addEventListener("storage", () => {
    setOnBoarding_step(sessionStorage.getItem("OnBoarding_step") == 3 ? 1 : 0);
  });
  return (
    <>
      <MetaTags>
        <title>
          {t("metadata.offers.title")} {window.localStorage.getItem("partnerSlug")}
        </title>
        <meta name="description" content={t("metadata.offers.description")} />
      </MetaTags>
      <SearchHeader
        query={searchFilters.query}
        setSearchQuery={setSearchQuery}
        isLoading={loading}
        semanticResponse={data?.search?.semanticResponse}
      />

      <Container maxWidth="lg">
        {hasPermission("onboarding") && showOnboarding && <Onboarding close={handleClose} />}

        <Box mt={7} mb={5} mx="auto" maxWidth="1100px">
          <Grid container spacing={2} direction="row" alignItems="center">
            <Grid item md={8}>
              {!loading && searchResultsText()}
            </Grid>
          </Grid>
          {!!data?.search?.count && minPrices && (
            <Box mt={3}>
              {loading ? (
                <Box width="100%" mb={30} textAlign="center">
                  <Loading size={48} color="secondary" />
                </Box>
              ) : (
                <PriceSlider
                  startingDay={1}
                  minPrices={minPrices}
                  departureDates={data?.search?.semanticResponse?.calendar?.departure_date?.[0]}
                />
              )}
            </Box>
          )}
        </Box>
        <Grid item mr={3}>
          {!loading && data?.search?.count > 0 && <Sorting sort={sort} setSort={setSort} />}
        </Grid>

        <Stack direction="row" mx="auto" mt={5} maxWidth="1100px">
          {!loading && data?.search?.count > 0 && (
            <Box
              className={styles.filters}
              style={{
                zIndex: 5000 * OnBoarding_step,
                important: true,
                backgroundColor: "white",
                borderRadius: "15px",
              }}
            >
              <SidebarFilters
                key={randomSidebarKey}
                searchFilters={searchFilters}
                setSearchFilters={setSearchFilters}
                responseFilters={data?.search?.facets}
                handleResetFilters={handleResetFilters}
              />
            </Box>
          )}
          {loading ? (
            <Box width="100%" mb={30} textAlign="center">
              <Loading size={48} color="secondary" />
            </Box>
          ) : (
            <OffersList
              offers={data?.search?.offers}
              count={data?.search?.count}
              page={pagination.page}
              query={searchFilters.query}
              queryId={queryId}
              semanticResponse={semanticResponse}
              handleResetFilters={handleResetFilters}
              handlePageChange={handlePageChange}
            />
          )}
        </Stack>
      </Container>
      <Box>
        <Footer />
      </Box>
    </>
  );
};

export default Offers;

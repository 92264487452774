import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import translationEN from "../locales/en/translation.json";
import translationPL from "../locales/pl/translation.json";

const resources = {
  en: {
    translation: translationEN,
  },
  pl: {
    translation: translationPL,
  },
};

let languageCode = "en";
if (window.navigator && window.navigator.language) {
  try {
    languageCode = window.navigator.language.split("-")[0] || "en";
  } catch (e) {}
}
const savedLanguageCode = window.localStorage.getItem("language_code");
if (savedLanguageCode) {
  languageCode = savedLanguageCode;
}

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options

  .init({
    resources,
    lng: window.localStorage.getItem("languageCode") ? window.localStorage.getItem("languageCode").toLowerCase() : "en",
    fallbackLng: "en",
    debug: false,

    whitelist: ["pl", "en"],

    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;

import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import icon from "../../images/icons/search-solid.svg";
import { hasPermission } from "../../utils/handlePermissions";
import styles from "./RelatedSearches.module.scss";
const RelatedSearches = () => {
  const { t } = useTranslation();
  const searches = new Array(6).fill("exclusive holidays on the islands");

  return (
    <>
      {hasPermission("show_related_searches") && (
        <Box>
          <h3>{t("offers_page.offers_list.related_searches")}</h3>
          <Box className={styles.relatedSearches}>
            {searches.map((search, index) => (
              <div key={index}>
                <img src={icon} alt="Search" className={styles.searchIcon}></img> {search}
              </div>
            ))}
          </Box>
        </Box>
      )}
    </>
  );
};

export default RelatedSearches;

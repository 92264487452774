import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import { Box, Modal, Typography } from "@mui/material";
import Button from "components/Base/Button";
import PocketBase from "pocketbase";
import { useState } from "react";
import useAuth from "../../../hooks/useAuth";
import styles from "./Feedback.module.scss";

const Feedback = ({ header, description, placeholder, save, cancel }) => {
  const [text, setText] = useState("");
  const [open, setOpen] = useState(false);
  const { user } = useAuth();

  const toggleModal = () => {
    setOpen(!open);
  };
  const handleTextChange = (event) => {
    setText(event.target.value);
  };

  const postFeedback = () => {
    const pb = new PocketBase(process.env.REACT_APP_POCKETBASE_URL);
    const data = {
      text: text,
      email: user.email,
    };
    pb.collection("feedback")
      .create(data)
      .then(() => {
        setOpen(false);
      });
  };

  return (
    <>
      <Box onClick={toggleModal} className={styles.feedbackIcon}>
        <Typography id="modal-modal-desc" variant="p">
          {header}
        </Typography>
      </Box>
      {open && (
        <Modal open={open} onClose={toggleModal}>
          <Box className={styles.feedback}>
            <Typography id="modal-modal-desc" variant="p" mb={10}>
              {description}
            </Typography>
            <TextareaAutosize
              className={styles.textarea}
              aria-label="minimum height"
              rows={5}
              placeholder={placeholder}
              onChange={handleTextChange}
            />
            <Box>
              <Button sx={{ m: 1, p: 2, fontSize: "0.75rem" }} className={styles.saveButton} onClick={postFeedback}>
                {save}
              </Button>
              <Button sx={{ m: 1, p: 2, fontSize: "0.75rem" }} className={styles.addButton} onClick={toggleModal}>
                {cancel}
              </Button>
            </Box>
          </Box>
        </Modal>
      )}
    </>
  );
};

export default Feedback;

import "react-hot-loader";

import ReactDOM from "react-dom";
import TagManager from "react-gtm-module";

import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import "styles/index.scss";
import App from "./App";
import { auth0Config } from "./config/auth0";
import { AuthProvider } from "./context/Auth0Context";
import reportWebVitals from "./reportWebVitals";
const tagManagerArgs = {
  gtmId: process.env.REACT_APP_TAG_MANAGER,
  dataLayer: {
    userId: window.localStorage.getItem("partnerSlug"),
    userProject: window.localStorage.getItem("partnerSlug"),
  },
};

TagManager.initialize(tagManagerArgs);

Sentry.init({
  environment: process.env.REACT_APP_SENTRY_ENV,
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <AuthProvider domain={auth0Config.domain} clientId={auth0Config.client_id} redirectUri={window.location.origin}>
    <App />
  </AuthProvider>,
  document.getElementById("qtravel-root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

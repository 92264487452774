import React from "react";
import { useTranslation } from "react-i18next";

import styles from "./OfferDetailsShort.module.scss";

const OfferDetailsShort = ({ offerType, destination, touroperator, availableTerms }) => {
  const { t } = useTranslation();

  return (
    <>
      <div id="shortDescription">
        <div className={styles.offerDetail}>
          <div>
            <span>{t("offer_page.short_details.offer_type")}</span> <span>{offerType}</span>
          </div>
          <div>
            <span>{t("offer_page.short_details.touroperator")}</span>
            <span> {touroperator}</span>
          </div>
          <div>
            <span>{t("offer_page.short_details.destination")} </span>
            <span>{destination}</span>
          </div>
          <div>
            <span>{t("offer_page.short_details.available_terms")} </span>
            <span>{availableTerms}</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default OfferDetailsShort;

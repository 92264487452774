import { Auth0Client } from "@auth0/auth0-spa-js";
import PropTypes from "prop-types";
import { createContext, useEffect, useReducer } from "react";
import { auth0Config } from "../config/auth0";
let auth0Client = null;
const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  isLoggedOut: false,
  user: null,
};
const checkPermission = (token) => {
  const tokenData = JSON.parse(atob(token.split(".")[1]));
  const permissions = tokenData.permissions.includes("Demo");
  if (!permissions) {
    window.location.replace(`${process.env.REACT_APP_DASHBOARD_URL}/?session=init`);
  }
};
const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;
    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    isLoggedOut: true,
    user: null,
  }),
};
const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);
const AuthContext = createContext({
  ...initialState,
  platform: "Auth0",
  loginWithPopup: () => Promise.resolve(),
  loginWithRedirect: (options) => Promise.resolve(),
  checkAuthorization: () => Promise.resolve(false),
  logout: () => Promise.resolve(),
});

export const AuthProvider = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);

  const handleUserData = (user, token) => {
    localStorage.setItem("token", token);
    localStorage.setItem("email", user.email);
    const tokenParts = token.split(".");
    if (tokenParts[1]) {
      const tokenData = JSON.parse(atob(tokenParts[1]));

      localStorage.setItem("qapiToken", tokenData["https://dashboard-api.qtravel.ai/app_metadata"]["qapi_token"]);
      localStorage.setItem("partnerSlug", tokenData["https://dashboard-api.qtravel.ai/app_metadata"]["partner_slug"]);
      localStorage.setItem("countryCode", tokenData["https://dashboard-api.qtravel.ai/app_metadata"]["country_code"]);
      localStorage.setItem("currency", tokenData["https://dashboard-api.qtravel.ai/app_metadata"]["currency"]);
      localStorage.setItem("languageCode", tokenData["https://dashboard-api.qtravel.ai/app_metadata"]["language_code"]);
      localStorage.setItem("adults", tokenData["https://dashboard-api.qtravel.ai/app_metadata"]["default_adults"]);
      localStorage.setItem(
        "partnerQapiUrl",
        tokenData["https://dashboard-api.qtravel.ai/app_metadata"]["partner_qapi_url"] !== undefined
          ? tokenData["https://dashboard-api.qtravel.ai/app_metadata"]["partner_qapi_url"]
          : process.env.REACT_APP_QAPI_BASE_URL
      );
      localStorage.setItem(
        "query_examples",
        JSON.stringify(tokenData["https://dashboard-api.qtravel.ai/app_metadata"]["query_examples"])
      );
      localStorage.setItem("permissions", JSON.stringify(tokenData.permissions));
    }
  };
  const removeUserData = () => {
    localStorage.removeItem("qapiToken");
    localStorage.removeItem("partnerSlug");
    localStorage.removeItem("token");
    localStorage.removeItem("countryCode");
    localStorage.removeItem("currency");
    localStorage.removeItem("languageCode");
    localStorage.removeItem("permissions");
    localStorage.removeItem("email");
    localStorage.removeItem("adults");
    localStorage.removeItem("query_examples");
    localStorage.removeItem("partnerQapiUrl");
  };

  useEffect(() => {
    const initialize = async () => {
      try {
        auth0Client = new Auth0Client({
          redirect_uri: window.location.origin,
          ...auth0Config,
        });
        await auth0Client.checkSession();
        const isAuthenticated = await auth0Client.isAuthenticated({ ignoreCache: true });
        if (isAuthenticated) {
          const user = await auth0Client.getUser();
          let token = null;
          try {
            token = await auth0Client.getTokenSilently();
          } catch (error) {
            if (error.error === "login_required") {
              // console.log('can not read iframe content - login won\'t work!')
            }
            throw "login will not work";
          }
          checkPermission(token);
          handleUserData(user, token);
          // Here you should extract the complete user profile to make it
          // available in your entire app.
          // The auth state only provides basic information.
          dispatch({
            type: "INITIALIZE",
            payload: {
              isAuthenticated,
              user: {
                id: user.sub,
                avatar: user.picture,
                email: user.email,
                name: "Anna Nowak",
                plan: "Premium",
              },
            },
          });
        } else {
          removeUserData();
          dispatch({
            type: "INITIALIZE",
            payload: {
              isAuthenticated,
              user: null,
            },
          });
        }
      } catch (err) {
        removeUserData();
        console.error(err);
        dispatch({
          type: "INITIALIZE",
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };
    initialize();
  }, []);
  const loginWithPopup = async (options) => {
    await auth0Client.loginWithPopup(options);
    const isAuthenticated = await auth0Client.isAuthenticated();
    if (isAuthenticated) {
      const user = await auth0Client.getUser();
      const token = await auth0Client.getTokenSilently();
      handleUserData(user, token);
      // Here you should extract the complete user profile to make it available in your entire app.
      // The auth state only provides basic information.
      dispatch({
        type: "LOGIN",
        payload: {
          user: {
            id: user.sub,
            avatar: user.picture,
            email: user.email,
            name: "Anna Nowak",
            plan: "Premium",
          },
        },
      });
    } else {
      removeUserData();
    }
  };
  const checkAuthorization = async () => {
    const queryStringFragments = window.location.href.split("?").slice(1);
    if (queryStringFragments[0]?.startsWith("code")) {
      await auth0Client.handleRedirectCallback();
    }
    const isAuthenticated = await auth0Client.isAuthenticated();
    if (isAuthenticated) {
      const user = await auth0Client.getUser();
      const token = await auth0Client.getTokenSilently();
      handleUserData(user, token);
      // Here you should extract the complete user profile to make it available in your entire app.
      // The auth state only provides basic information.
      dispatch({
        type: "LOGIN",
        payload: {
          user: {
            id: user.sub,
            avatar: user.picture,
            email: user.email,
            name: "Anna Nowak",
            plan: "Premium",
          },
        },
      });
    } else {
      removeUserData();
    }
    return isAuthenticated;
  };
  const loginWithRedirect = async (options) => {
    await auth0Client.loginWithRedirect(options);
  };
  const logout = () => {
    auth0Client.logout();
    removeUserData();
    dispatch({
      type: "LOGOUT",
    });
  };
  return (
    <AuthContext.Provider
      value={{
        ...state,
        platform: "Auth0",
        loginWithPopup,
        loginWithRedirect,
        checkAuthorization,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
export default AuthContext;

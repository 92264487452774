import Slider from "react-slick";
import styles from "./Offers.module.scss";
import { addDays, differenceInDays, format, subDays } from "date-fns";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { StringParam, useQueryParams } from "use-query-params";

const PriceSlider = ({ startingDay, minPrices, departureDates }) => {
  const [departureDate, setDepartureDate] = useQueryParams({ departureDate: StringParam });
  const { t } = useTranslation();

  let dayCount = 30;
  let tomorrow = format(addDays(new Date(), 1), "yyyy-MM-dd");
  if (departureDates && departureDates.min && departureDates.max) {
    startingDay = 0;
    dayCount = differenceInDays(new Date(departureDates.max), new Date(departureDates.min)) + 1;
  }
  let dateStart = new Date();
  if (departureDates && departureDates.min) {
    startingDay = 0;
    dateStart = new Date(departureDates.min);
  } else if (departureDates && departureDates.max) {
    startingDay = 0;
    dateStart = subDays(new Date(departureDates.max), dayCount - 1);
  }

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: dayCount < 6 ? dayCount : 6,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: dayCount < 4 ? dayCount : 4,
          slidesToScroll: dayCount < 4 ? dayCount : 4,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: dayCount < 3 ? dayCount : 3,
          slidesToScroll: dayCount < 3 ? dayCount : 3,
          initialSlide: dayCount < 3 ? dayCount : 3,
        },
      },
    ],
  };

  return (
    <Slider {...settings}>
      {Array.from({ length: dayCount }).map((_, index) => {
        const day = startingDay + index;
        const dateKey = format(addDays(dateStart, day), "yyyy-MM-dd");
        const dateText = tomorrow === dateKey ? t("main.tomorrow") : dateKey;
        return (
          <Box
            key={day}
            className={styles.priceBox}
            onClick={() => setDepartureDate({ departureDate: `min-${dateKey}_max-${dateKey}` })}
          >
            <Box>{dateText}</Box>
            {minPrices[dateKey] && (
              <Box fontWeight={"bold"}>
                <span>{t("main.from")}</span> {minPrices[dateKey]}{" "}
                <span>{window.localStorage.getItem("currency") || ""}</span>
              </Box>
            )}
            {!minPrices[dateKey] && <span>{t("main.no_trips")}</span>}
          </Box>
        );
      })}
    </Slider>
  );
};

export default PriceSlider;

import { Loader } from "components/Base";
import OfferDetails from "components/Offer/OfferDetails";
import OfferDetailsShort from "components/Offer/OfferDetailsShort";
import OfferTerms from "components/Offer/OfferTerms";
import { SingleOfferContext } from "context/SingleOfferContext";
import { useContext } from "react";
import TagManager from "react-gtm-module";
import { useTranslation } from "react-i18next";
import MetaTags from "react-meta-tags";
import { useNavigate } from "react-router-dom";
import { NOTFOUND_PATH } from "../../../config/routes";
import { hasPermission } from "../../../utils/handlePermissions";
import OfferNav from "../OfferNav/OfferNav";
import RelatedOffers from "../RelatedOffers/RelatedOffers";
import styles from "./SingleOffer.module.scss";

import useSnowplowAnalytics from "../../../hooks/useSnowplowAnalytics";
import ContactBox from "components/ContactBox/ContactBox";
import { SCHEMA_SELECT_ITEM, SCHEMA_VIEW_ITEM } from "../../../config/snowplow";
import OfferReservationShort from "../OfferReservationShort";

const SingleOffer = () => {
  const { t } = useTranslation();
  const { offer, similarOffers } = useContext(SingleOfferContext);
  const navigate = useNavigate();
  const analytics = useSnowplowAnalytics();

  if (offer.offer === undefined) {
    return <Loader />;
  }
  if (similarOffers === undefined && hasPermission("show_related_offers")) {
    return <Loader />;
  } else {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }
  TagManager.dataLayer({
    dataLayer: {
      event: "view_item",
      currency: "PLN",
      items: {
        item_id: offer.offer.offerDetails.id,
        item_name: offer.offer.offerDetails.name,
      },
    },
  });

  analytics.track("view_item", {
    contexts: [
      {
        schema: SCHEMA_VIEW_ITEM,
        data: {
          event: "view_item",
          item_id: offer.offer.offerDetails.id,
          item_name: offer.offer.offerDetails.name,
          currency: "PLN",
        },
      },
    ],
  });

  TagManager.dataLayer({
    dataLayer: {
      event: "select_item",
      language: window.localStorage.getItem("i18nextLng"),
      userProject: window.localStorage.getItem("partnerSlug"),
      userId: window.localStorage.getItem("email").replace("@qtravel.ai", ""),
      items: [
        {
          item_id: offer.offer.offerDetails.id,
          item_name: offer.offer.offerDetails.name,
          index: window.localStorage.getItem("position"),
          location_id: window.localStorage.getItem("page"),
          price:
            offer.offer.offerDetails.terms[0] &&
            offer.offer.offerDetails.terms[0].personPrices &&
            offer.offer.offerDetails.terms[0].personPrices[0].price,
        },
      ],
    },
  });

  analytics.track("select_item", {
    contexts: [
      {
        schema: SCHEMA_SELECT_ITEM,
        data: {
          event: "select_item",
          language: window.localStorage.getItem("i18nextLng"),
          userProject: window.localStorage.getItem("partnerSlug"),
          userId: window.localStorage.getItem("email").replace("@qtravel.ai", ""),
          items: [
            {
              item_id: offer.offer.offerDetails.id,
              item_name: offer.offer.offerDetails.name,
              index: window.localStorage.getItem("position"),
              location_id: window.localStorage.getItem("page"),
              price:
                offer.offer.offerDetails.terms[0] &&
                offer.offer.offerDetails.terms[0].personPrices &&
                offer.offer.offerDetails.terms[0].personPrices[0].price,
            },
          ],
        },
      },
    ],
  });

  const getNameFromData = (str) => {
    try {
      if (Array.isArray(str)) {
        return Array.isArray(str[0].sourceName)
          ? str[0].sourceName.join(" / ")
          : JSON.parse(str[0].sourceName).join(" / ");
      } else {
        return Array.isArray(str.sourceName) ? str.sourceName.join(" / ") : JSON.parse(str.sourceName).join(" / ");
      }
    } catch (e) {
      return str;
    }
  };

  if (!offer.offer.offerDetails.id) {
    navigate(NOTFOUND_PATH);
    return null;
  }

  const offerDetails = {
    title: t("offer_page.short_details.title"),
    description: offer.offer.offerDetails.descriptionHtml[0].body || "-",
    offerType: offer.offer.offerDetails.type || "-",
    destination: getNameFromData(offer.offer.offerDetails.countries) || "-",
    touroperator: offer.offer.offerDetails.touroperator.name || "-",
    availableTerms: offer.offer.offerDetails.termsCount || "-",
    semantic: {
      semanticCategories: offer.offer.offerDetails.semanticCategories,
      detectedSemanticCategories: offer.offer.offerDetails.detectedSemanticCategories,
    },
    customFields: offer.offer.offerDetails.customFields || {},
  };

  const description = offerDetails.description;
  const semantic = offerDetails.semantic;

  const formatDescriptionData = (data) => {
    let result = "";
    try {
      result = JSON.parse(data)
        .map((item) => (item.body ? item.body.replace(/^"+/, "").replace(/"+$/, "") : `<p>${item.label}</p>`))
        .join("");
    } catch (e) {
      result = data;
    }
    return result;
  };
  const formatDescription = <div dangerouslySetInnerHTML={{ __html: formatDescriptionData(description) }} />;

  return (
    <>
      <MetaTags>
        <title>{t("metadata.offer.title") + offer.offer.offerDetails.name}</title>
        <meta name="description" content={t("metadata.homepage.description")} />
      </MetaTags>
      <div className={styles.singleOffer}>
        <OfferNav />
        <OfferDetailsShort
          offerType={offerDetails.offerType}
          destination={offerDetails.destination}
          touroperator={offerDetails.touroperator}
          availableTerms={offerDetails.availableTerms}
        />
        <div className={styles.termContainer}>
          <OfferTerms />
          <OfferReservationShort offer={offer} />
        </div>
        {hasPermission("contact_box") && (
          <ContactBox
            contextTextButton={t("shared.contact_us")}
            contextText={t("shared.contact_box_content")}
            buttonColor={"#ff7427"}
            boxColor={"#e9f9ff"}
          />
        )}
        <OfferDetails
          description={formatDescription}
          semantic={semantic}
          item_id={offer.offer.offerDetails.id}
          item_name={offer.offer.offerDetails.name}
          opinions={offerDetails.customFields}
        />
        {hasPermission("show_related_offers") && (
          <>{similarOffers == undefined ? <Loader /> : <RelatedOffers relatedOffers={similarOffers} />}</>
        )}
      </div>
    </>
  );
};

export default SingleOffer;

import { OFFERS_PATH } from "config/routes";
import { useWindowDimensions } from "hooks/useWindowDimensions";
import TagManager from "react-gtm-module";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { StringParam, useQueryParam } from "use-query-params";

import _ from "lodash";

import { Box, Typography, Tooltip } from "@mui/material";
import { Button } from "components/Base";

import { hasPermission } from "../../utils/handlePermissions";
import ClassicSearchForm from "../ClassicSearch";
import styles from "./HomeForm.module.scss";

import { Autocomplete } from "qtravelpl_autocomplete";
import icon from "../../images/icons/search-solid.svg";
import { ClassicSearchIcon } from "components/Icons";

import { SCHEMA_SEARCH, SCHEMA_SEARCH_BY_ENTER } from "../../config/snowplow";
import useSnowplowAnalytics from "../../hooks/useSnowplowAnalytics";
import { useState } from "react";
import { T } from "lodash/fp";

const HomeForm = ({ homePageTheme }) => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
  const { t } = useTranslation();
  const { width } = useWindowDimensions();
  const [query, setQuery] = useQueryParam("query", StringParam);
  const [classicSearch, setClassicSearch] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const analytics = useSnowplowAnalytics();

  const goToOffers = (withQuery = "") => {
    navigate({
      pathname: OFFERS_PATH,
      search: withQuery ? `?query=${encodeURIComponent(withQuery)}` : location.search,
    });
    TagManager.dataLayer({
      dataLayer: {
        event: "search",
        search_term: withQuery ? withQuery : query,
        language: window.localStorage.getItem("i18nextLng"),
        userProject: window.localStorage.getItem("partnerSlug"),
        userId: window.localStorage.getItem("email").replace("@qtravel.ai", ""),
      },
    });
    analytics.track("search", {
      contexts: [
        {
          schema: SCHEMA_SEARCH,
          data: {
            event: "search",
            search_term: withQuery ? withQuery : query,
            language: window.localStorage.getItem("i18nextLng"),
            userProject: window.localStorage.getItem("partnerSlug"),
            userId: window.localStorage.getItem("email").replace("@qtravel.ai", ""),
          },
        },
      ],
    });
  };
  const setSearchText = (e) => setQuery(_.trim(e.target.name, "\n"));
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      goToOffers();
      TagManager.dataLayer({
        dataLayer: {
          event: "searchByEnter",
          search_term: query,
          language: window.localStorage.getItem("i18nextLng"),
          userProject: window.localStorage.getItem("partnerSlug"),
          userId: window.localStorage.getItem("email").replace("@qtravel.ai", ""),
        },
      });
      analytics.track("searchByEnter", {
        contexts: [
          {
            schema: SCHEMA_SEARCH_BY_ENTER,
            data: {
              event: "searchByEnter",
              search_term: query,
              language: window.localStorage.getItem("i18nextLng"),
              userProject: window.localStorage.getItem("partnerSlug"),
              userId: window.localStorage.getItem("email").replace("@qtravel.ai", ""),
            },
          },
        ],
      });
    }
  };

  const examplesTmp = localStorage.getItem("query_examples");
  let examples = [];
  try {
    examples = examplesTmp && examplesTmp !== "undefined" ? JSON.parse(examplesTmp) : [];
  } catch (e) {
    examples = [];
  }

  const search_btn_id = () => {
    switch (window.localStorage.getItem("partnerSlug")) {
      case "demo_pl_classic_search":
        return t("id.button_main_search_id_classic");
      case "demo_en_classic_search":
        return t("id.button_main_search_id_classic");
      case "demo_pl_full_text_search":
        return t("id.button_main_search_id_fts");
      case "demo_en_full_text_search":
        return t("id.button_main_search_id_fts");
      default:
        return t("id.button_main_search_id");
    }
  };
  return (
    <Box display="flex" flexDirection="column" justifyContent="space-between" alignItems="center">
      <Box display="flex" justifyContent="space-between" mt={2} width="100%" maxWidth="1100px">
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-evenly"
          width="50%"
          mx={0}
          sx={{
            width: ["100%", "60%", "70%", "50%"],
            marginBottom: [4, 4, 0],
            padding: [3, 3, 0],
          }}
        >
          <Typography
            variant="h1"
            fontWeight="500"
            color={homePageTheme.background.color}
            className={styles.header}
            sx={{
              fontSize: ["xx-large", "xx-large", "xxx-large", "xxx-large"],
              paddingLeft: [0, 0, 3, 0],
            }}
          >
            {t("main.search_hello")}
            <br />
            {t("main.search_hello_2")}
          </Typography>
          {!hasPermission("can_view_classic_form") &&
            !classicSearch &&
            window.localStorage.getItem("partnerSlug") !== "rainbow" && (
              <Box alignSelf="flex-start" id="homepage_examples">
                {!!examples &&
                  !!examples.length &&
                  examples.map((example, index) => (
                    <Button
                      name={decodeURIComponent(escape(example.example))}
                      size="large"
                      variant="text"
                      sx={{ color: homePageTheme.background.color }}
                      onClick={setSearchText}
                      key={`example-${index + 1}`}
                      id={`homepage-example-${index + 1}`}
                    >
                      {t("shared.example")} {index + 1}
                    </Button>
                  ))}
              </Box>
            )}
        </Box>
        <Box className={styles.backgroundImage} width="50%">
          {homePageTheme.boxImage}
        </Box>
      </Box>
      {!hasPermission("can_view_classic_form") && (
        <Box className={styles.formWrapper}>
          {!classicSearch && (
            <Box className={styles.textareaContainer}>
              <textarea
                value={query}
                maxLength={300}
                onKeyDown={handleKeyDown}
                id="homepage-search"
                onKeyPress={handleKeyPress}
                className={styles.formSearch}
                placeholder={width < 500 ? t("main.form_placeholder_mobile") : t("main.form_placeholder")}
                onChange={(e) => {
                  setQuery(_.trim(e.target.value, "\n"));

                  const autocompleteDiv = document.querySelector(".autocompleteContainer");
                  if (autocompleteDiv) autocompleteDiv.style.display = "block";
                }}
                required
              />
              {!classicSearch && (
                <Button onClick={() => goToOffers()} id={search_btn_id()} className={styles.searchButton}>
                  <img src={icon} alt="Search" className={styles.searchIcon}></img>
                </Button>
              )}
            </Box>
          )}
          {hasPermission("autocomplete") && (
            <Autocomplete
              uri={localStorage.getItem("partnerQapiUrl")}
              query={query}
              hideOnClick={true}
              itemClicked={(item) => {
                setQuery(item.value.join(" "));
                goToOffers(item.value.join(" "));
                const autocompleteDiv = document.querySelector(".autocompleteContainer");
                if (autocompleteDiv) autocompleteDiv.style.display = "none";
              }}
              countryCode={window.localStorage.getItem("countryCode")}
              languageCode={window.localStorage.getItem("languageCode")}
              qapiToken={window.localStorage.getItem("qapiToken")}
              token={window.localStorage.getItem("token")}
            />
          )}
          {hasPermission("classic_hybrid_search") && !classicSearch && (
            <Box className={styles.classicSearch_container}>
              <a style={{ color: "#0b4356" }}>{t("main.classic_search")}</a>
              <Button className={styles.classicSearch_button} onClick={() => setClassicSearch(!classicSearch)}>
                <ClassicSearchIcon></ClassicSearchIcon>
              </Button>
            </Box>
          )}
        </Box>
      )}
      {(!!hasPermission("can_view_classic_form") || classicSearch) && (
        <Box mb={15} className={classicSearch ? styles.classicSearch_box : null}>
          <ClassicSearchForm
            goToOffers={goToOffers}
            ClassicSearch={classicSearch}
            setClassicSearch={setClassicSearch}
          />
        </Box>
      )}
    </Box>
  );
};

export default HomeForm;

import { gql } from "@apollo/client";

export const GET_OFFER = gql`
  query GetOffer(
    $countryCode: Country!
    $languageCode: Language!
    $id: ID!
    $filters: Filters
    $order: [Order!]
    $pagination: Pagination
  ) {
    offer(
      countryCode: $countryCode
      languageCode: $languageCode
      id: $id
      filters: $filters
      order: $order
      pagination: $pagination
    ) {
      filters {
        propertyCategory {
          count
          value
        }
      }
      offerDetails {
        id
        name
        cities {
          sourceName
        }
        countries {
          sourceName
        }
        regions {
          id
          sourceName
        }
        property {
          id
          sourceName
          category
          facilities
          giataId
          id
          latitude
          longitude
          name
          sourceCategory
          category
          sourceId
          type
        }
        terms {
          id
          tripCode
          departureCity
          departureDate
          basePrice
          persons
          personPrices {
            age
            ageRange
            price
            type
          }
          totalPrice
          transportType
          period
          maintenances {
            sourceName
            description
            name
          }
          personsConfiguration {
            adults
            children
            #childrenBirthDates
            foundAdults
            #foundChildren
            #foundChildrenAges
            #foundChildrenBirthDates
          }
        }
        termsCount
        description
        descriptionHtml {
          body
        }
        images {
          original
        }
        type
        touroperator {
          name
        }
        detectedSemanticCategories {
          accommodation
          activity
          boardType
          cuisine
          hotelAmenities
          hotelAttractions
          hotelType
          localization
          nature
          offerType
          other
          roomAmenities
          roomType
          skiing
          transportation
          tripAttractions
          tripType
        }
        semanticCategories {
          accommodation
          activity
          boardType
          cuisine
          hotelAmenities
          hotelAttractions
          hotelType
          localization
          nature
          offerType
          other
          roomAmenities
          roomType
          skiing
          transportation
          tripAttractions
          tripType
        }
      }
    }
  }
`;

import React from "react";

import { SingleOfferProvider } from "context/SingleOfferContext";

import Footer from "components/Footer";
import OfferHeader from "components/Offer/OfferHeader";
import SingleOffer from "components/Offer/SingleOffer";

import styles from "./Offer.module.scss";

import { FiltersProvider } from "context/FiltersContext";

const Offer = () => {
  return (
    <div>
      <FiltersProvider>
        <SingleOfferProvider>
          <OfferHeader />
          <div className={styles.offerWrapper}>
            <div className={styles.container}>
              <SingleOffer />
            </div>
          </div>
        </SingleOfferProvider>
      </FiltersProvider>
      <Footer />
    </div>
  );
};

export default Offer;

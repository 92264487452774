import TagManager from "react-gtm-module";
import OfferItem from "./OfferItem";

import { Pagination, Stack } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { hasPermission } from "../../utils/handlePermissions";
import RelatedSearches from "../RelatedSearches/RelatedSearches";
import styles from "./OffersList.module.scss";

import useSnowplowAnalytics from "../../hooks/useSnowplowAnalytics";
import ContactBox from "components/ContactBox/ContactBox";
import { useTranslation } from "react-i18next";
import { SCHEMA_VIEW_ITEM_LIST } from "../../config/snowplow";

const OffersList = ({ offers, count, page, query, queryId, semanticResponse, handlePageChange }) => {
  const { t } = useTranslation();

  const dataLayerData =
    offers &&
    offers.map((offer, index) => ({
      item_id: offer.id,
      item_name: offer.name,
      index: index + 1,
      item_variant: offer.count,
      location_id: page,
    }));
  const [predictions, setPredictions] = useState({});
  const analytics = useSnowplowAnalytics();

  function getTripCodes(offers) {
    const tripCodes = [];
    offers.forEach((offer) => {
      tripCodes.push(offer.terms[0].tripCode);
    });
    return tripCodes;
  }

  useEffect(() => {
    if (!offers) {
      return;
    }
    if (hasPermission("prediction")) {
      axios
        //TODO: Move whole fetch login to another reusable component
        .post(process.env.REACT_APP_PREDICT_API_URL, { trip_codes: getTripCodes(offers) })
        .then((data) => {
          const predictionsTmp = {};
          offers.forEach((offer) => {
            const firstTerm = offer.terms[0];

            if (Object.keys(data.data.forecasts).length < 1) {
              return;
            }
            const price = offer.terms[0].personPrices?.[0]?.price;
            const forecast = Object.entries(data.data.forecasts).reduce(
              (acc, [key, value]) => ({
                ...acc,
                [key.replace(/-/g, "")]: value,
              }),
              {}
            )[firstTerm.tripCode];
            if (offer && offer.terms && offer.terms[0] && offer.terms[0].tripCode) {
              const tripCode = offer.terms[0].tripCode;
              if (forecast) {
                const dates = Object.keys(forecast);
                const prices = Object.values(forecast);
                const lastPredictionDate = dates[dates.length - 1];
                const lastPredictionPrice = prices[prices.length - 1];
                if (lastPredictionPrice > price) {
                  predictionsTmp[tripCode] = { state: "up", price: lastPredictionPrice, date: lastPredictionDate };
                } else if (lastPredictionPrice < price) {
                  predictionsTmp[tripCode] = { state: "down", price: lastPredictionPrice, date: lastPredictionDate };
                } else {
                  predictionsTmp[tripCode] = { state: "same", price: lastPredictionPrice, date: lastPredictionDate };
                }
              } else {
                predictionsTmp[tripCode] = { state: "error" };
              }
            }
          });
          setPredictions(predictionsTmp);
        })
        .catch((error) => {
          console.log(error);
          const predictionsTmp = {};
          offers.forEach((offer) => {
            predictionsTmp[offer.id] = { state: "error" };
          });
          setPredictions(predictionsTmp);
        });
    }
  }, [offers]);

  TagManager.dataLayer({
    dataLayer: {
      event: "view_item_list",
      search_term: query,
      language: window.localStorage.getItem("i18nextLng"),
      userProject: window.localStorage.getItem("partnerSlug"),
      userId: window.localStorage.getItem("email").replace("@qtravel.ai", ""),
      item_list_id: page,
      items: dataLayerData,
    },
  });
  analytics.track("view_item_list", {
    contexts: [
      {
        schema: SCHEMA_VIEW_ITEM_LIST,
        data: {
          event: "view_item_list",
          search_term: query,
          language: window.localStorage.getItem("i18nextLng"),
          userProject: window.localStorage.getItem("partnerSlug"),
          userId: window.localStorage.getItem("email").replace("@qtravel.ai", ""),
          item_list_id: page,
          items: dataLayerData,
          count: count,
        },
      },
    ],
  });
  const middleIndex = Math.floor(offers?.length / 2);

  return (
    <div className={styles.offersList}>
      {offers &&
        offers.reduce((acc, offer, index) => {
          acc.push(
            <OfferItem
              semanticResponse={semanticResponse}
              offer={offer}
              count={count}
              query={query}
              queryId={queryId}
              item_name={offer.name}
              index={index}
              key={offer.id}
              page={page}
              prediction={predictions[offer.terms[0].tripCode] || { state: "loading" }}
              isRelatedOffer={false}
            />
          );
          if (index === middleIndex) {
            acc.push(
              hasPermission("contact_box") && (
                <ContactBox
                  contextTextButton={t("shared.contact_us")}
                  contextText={t("shared.contact_box_content")}
                  buttonColor={"#ff7427"}
                  boxColor={"#e9f9ff"}
                />
              )
            );
          }
          return acc;
        }, [])}
      {offers && offers.length !== 0 && (
        <Stack alignItems="center" mb={5}>
          <RelatedSearches />
          <Pagination page={parseInt(page || "1", 10)} count={Math.ceil(count / 10)} onChange={handlePageChange} />
        </Stack>
      )}
    </div>
  );
};

export default OffersList;

import React from "react";
import { hot } from "react-hot-loader";

import { ApolloClient, ApolloProvider, from, HttpLink, InMemoryCache } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import SearchContextProvider from "./context/SearchContext";

import "config/i18n";
import { THEME_CONFIG } from "config/theme";

import "./styles/App.scss";

import Router from "./Router";
import { setContext } from "@apollo/client/link/context";

const errorLink = onError(({ graphqlErrors, networkError }) => {
  if (graphqlErrors) {
    graphqlErrors.forEach(({ message, location, path }) => {
      const errMsg = `[GraphQL error]: Message: ${message}, Location: ${location}, Path: ${path}`;
      alert(`Graphl error ${errMsg}`);
    });
  }
});
const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem("qapiToken");
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});
const partnerUrl = localStorage.getItem("partnerQapiUrl");
const link = from([errorLink, authLink, new HttpLink({ uri: partnerUrl })]);

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: link,
  watchQuery: {
    nextFetchPolicy: "cache-only",
  },
});

const theme = createTheme(THEME_CONFIG);

function App() {
  return (
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <SearchContextProvider>
          <ApolloProvider client={client}>
            <Router />
          </ApolloProvider>
        </SearchContextProvider>
      </ThemeProvider>
    </React.StrictMode>
  );
}

export default process.env.NODE_ENV === "development" ? hot(module)(App) : App;
